import { useNavigate } from "react-router-dom";
import { upgradeOptionsData } from "../../utils/helpers";

export default function UpgradeOptions({
  selected,
  handleChange,
  fieldName,
  userType,
  userDeposits,
  userRefers,
}) {
  const navigate = useNavigate();
  return (
    <div className="my-5">
      <div className="mt-4 max-sm:flex-col flex gap-5">
        {upgradeOptionsData(userDeposits, userType, userRefers).map(
          ({ title, text, buttonText, href, value }, index) => (
            <div
              onClick={() => {
                handleChange(value);
                navigate(href);
              }}
              className={"w-full"}
              key={index}
            >
              <input
                type="radio"
                id={index + 0.355}
                className="hidden"
                value={value}
                onChange={handleChange}
                name={fieldName}
              />
              <label htmlFor={value}>
                <div
                  className={`${
                    value === selected && "body-shadow"
                  } flex max-sm:justify-between sm:gap-x-5 hover:cursor-pointer items-center max-sm:space-x-2 bg-gray-800/70 rounded-lg p-4 pb-6`}
                >
                  <div>
                    <div
                      className={`${
                        value === selected
                          ? "border-4 border-[#3cbe62]"
                          : "border border-divider-color-primary"
                      } appearance-none w-4 h-4 cursor-pointer rounded-full bg-gray-300`}
                    ></div>
                  </div>
                  <div className="flex items-center space-x-3">
                    <div className="flex flex-col gap-y-3">
                      <div>
                        <h3 className="font-medium text-[18px] text-grey-800 text-sm">
                          {title}
                        </h3>
                        <p className="text-grey-600 text-sm">{text}</p>
                      </div>

                      <button
                        onClick={() => navigate(href)}
                        className="button w-fit"
                      >
                        {buttonText}
                      </button>
                    </div>
                  </div>
                </div>
              </label>
            </div>
          )
        )}
      </div>
    </div>
  );
}
