import { useContext, useEffect, useState } from "react";
import client from "../utils/client";
import { AuthContext } from "../context/authContext";

export default function useUser() {
  const [realTimeUser, setRealTimeUser] = useState(null);

  const { user } = useContext(AuthContext);

  function getUserDetails() {
    client({ url: `/auth/echo?email=${user.email}`, method: "GET" })
      .then((res) => {
        setRealTimeUser(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    realTimeUser,
  };
}
