import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CloseIcon } from "./svgs";

const CookieAlert = ({ setShowComponent, showComponent }) => {
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  // Check localStorage to see if the alert should be displayed
  useEffect(() => {
    const consentGiven = localStorage.getItem("cookieConsent");
    if (!consentGiven) {
      setIsVisible(true); // Show the alert if no consent is found
    }
  }, []);

  // Handle closing the alert and saving consent to localStorage
  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setIsVisible(false); // Hide the alert
  };

  // if (!isVisible) return null; // If not visible, don't render anything

  return (
    <div
      style={styles.container}
      className={`px-5 sm:px-9 md:px-10 backdrop-blur-3xl relative lg:px-20 xl:px-[184px] py-5 transition-all duration-500 ${
        isVisible ? "animate-fadeIn" : "ease-out opacity-0"
      }`}
    >
      <div className="justify-between md:flex gap-x-4 items-center w-full">
        <div className="md:w-[80%]">
          <h2 className="text-xl font-medium pb-2">Boost Tier Upgrades:</h2>
          <p style={styles.message}>
            Introducing Enhanced Boost Tiers for Improved Portfolio Management.
            For a limited time, unlock early access to VIP and upcoming
            strategies, increased referral rewards, and priority support.
          </p>
        </div>
        <div className="flex flex-col gap-y-4 items-end">
          <div
            onClick={handleAccept}
            className="absolute cursor-pointer right-5 top-5"
          >
            <CloseIcon />
          </div>
          <div className="p-1 max-md:mt-3 max-sm:mt-3 max-sm:w-full rounded-md bg-gradient-to-r from-[#34d399] via-[#b2ffce] to-[#10b981] animate-gradient-bg bg-200% hover:scale-105 duration-500">
            <button
              style={styles.button}
              onClick={() => {
                setIsVisible(false);
                navigate("/boost-tier");
              }}
              className="!px-12 hover:scale-100 max-md:w-full"
            >
              Get started
            </button>
          </div>
          <button onClick={() => setIsVisible(false)}>
            <p className="underline">Hide</p>
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    // padding: "1rem",
    // backgroundColor: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    zIndex: 9999999999,
  },
  message: {
    marginRight: "1rem",
  },
  button: {
    padding: "0.5rem 1rem",
    color: "white",
    backgroundColor: "rgba(0, 0, 0)",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default CookieAlert;
