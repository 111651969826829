import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import GlowButton from "../components/lib/GlowButton";

const Tiers = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <div className="bg-[#0a0c0f]">
      <div className="">
        <div className="py-9 md:p-7 lg:p-14">
          <div className="p-[5px] rounded-lg gradient__btn">
            <div className="bg-[#181b20] md:p-8 rounded-md p-3">
              <div className="xl:flex items-center justify-between">
                <div className="xl:max-w-[80%]">
                  <h1 className="font-medium text-4xl text-gray-300 ">
                    Algoo Strategies Boost Tiers
                  </h1>

                  <p className="text-gray-400 font-sm mt-2 lg:max-w-[80%]">
                    The Algoo Strategies Boost Tier Program is a four-level
                    system designed to reward users who invest in our
                    strategies. As your portfolio grows, you'll unlock higher
                    tiers, granting you exclusive benefits and access to premium
                    strategies for enhanced returns.
                  </p>
                </div>
                <div className="xlg:w-1/5 xl:flex max-sm:w-full max-xl:mt-4 justify-end">
                  <button
                    onClick={() => navigate("/boost-tier")}
                    className="button !px-9 max-sm:w-full gradient__btn"
                  >
                    <h2 className="font-medium">Boost Your Tier</h2>
                  </button>
                </div>
              </div>
              <div className="2xl:grid grid-cols-4 my-8 gap-7 shadow-2xl max-[1300px]:space-y-5">
                <div
                  className={`p-4 rounded-xl ${
                    user?._type === "Base"
                      ? "bg-green-700 bg-opacity-20"
                      : "bg-[#0a0c0f]"
                  }`}
                >
                  <svg fill="none" viewBox="0 0 32 32" width="24" height="24">
                    <rect
                      width="32"
                      height="32"
                      rx="16"
                      fill="#fff"
                      fill-opacity=".16"
                    ></rect>
                    <path
                      d="M18.37 10.96c-.75-1.5-1.13-2.26-1.62-2.5a1.67 1.67 0 0 0-1.5 0c-.5.24-.87 1-1.62 2.5-.2.41-.31.62-.45.78-.18.2-.4.36-.66.46-.2.08-.43.1-.87.15-1.56.17-2.33.26-2.72.58-.5.4-.7 1.06-.55 1.68.12.48.7 1.03 1.86 2.1.36.34.54.51.66.7.19.3.27.65.25 1-.02.22-.1.45-.26.9-.52 1.5-.79 2.25-.69 2.7.16.72.76 1.26 1.49 1.32.47.04 1.18-.31 2.6-1.02l.52-.27c.43-.21.65-.32.88-.37.2-.03.41-.03.62 0 .23.05.45.16.88.37l.53.27c1.41.7 2.12 1.06 2.6 1.02.72-.06 1.32-.6 1.48-1.31.1-.46-.17-1.2-.7-2.7-.15-.46-.23-.7-.25-.92-.02-.34.06-.7.25-.99.12-.19.3-.36.66-.7 1.16-1.07 1.74-1.62 1.86-2.1a1.67 1.67 0 0 0-.55-1.68c-.39-.32-1.16-.4-2.72-.58a3.66 3.66 0 0 1-.87-.15c-.25-.1-.48-.26-.66-.46a3.75 3.75 0 0 1-.45-.78Z"
                      fill="#fff"
                      fill-opacity=".36"
                    ></path>
                  </svg>
                  <h1 className="text-gray-300 text-2xl font-medium mb-2 mt-4">
                    Base
                  </h1>
                  <div className="text-gray-400">
                    <div className="space-y-3">
                      <p>Entry-level account </p>{" "}
                      <p>
                        Maximum of 2 strategies: Allows users to select and
                        execute up to 2 trading strategies.
                      </p>
                      <p>
                        Minimum investment of $500: Provides accessibipty for
                        users with a lower starting capital.
                      </p>
                      <p>
                        Diversification: Enables users to diversify their
                        portfolio by implementing multiple strategies.
                      </p>
                    </div>
                  </div>
                  <div className="w-full">
                    {user?._type === "Base" ? (
                      <div className="flex space-x-2 text-green-400 items-center mt-5">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-4 h-4"
                          >
                            <path
                              fillRule="evenodd"
                              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <div>
                          <h1 className="font-medium">Current</h1>
                        </div>
                      </div>
                    ) : (
                      <Link to="/boost-tier">
                        <button className="py-1 w-32 bg-[#b2ffce] text-gray-800 rounded-md mx-auto mt-6 text-sm">
                          Upgrade
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
                <div
                  className={`p-4 rounded-3xl ${
                    user?._type === "Bronze"
                      ? "bg-green-700 bg-opacity-20"
                      : "bg-[#0a0c0f]"
                  }`}
                >
                  <svg fill="none" viewBox="0 0 32 32" width="24" height="24">
                    <rect width="32" height="32" rx="16" fill="#FFA858"></rect>
                    <path
                      d="M18.37 10.96c-.75-1.5-1.13-2.26-1.62-2.5a1.67 1.67 0 0 0-1.5 0c-.5.24-.87 1-1.62 2.5-.2.41-.31.62-.45.78-.18.2-.4.36-.66.46-.2.08-.43.1-.87.15-1.56.17-2.33.26-2.72.58-.5.4-.7 1.06-.55 1.68.12.48.7 1.03 1.86 2.1.36.34.54.51.66.7.19.3.27.65.25 1-.02.22-.1.45-.26.9-.52 1.5-.79 2.25-.69 2.7.16.72.76 1.26 1.49 1.32.47.04 1.18-.31 2.6-1.02l.52-.27c.43-.21.65-.32.88-.37.2-.03.41-.03.62 0 .23.05.45.16.88.37l.53.27c1.41.7 2.12 1.06 2.6 1.02.72-.06 1.32-.6 1.48-1.31.1-.46-.17-1.2-.7-2.7-.15-.46-.23-.7-.25-.92-.02-.34.06-.7.25-.99.12-.19.3-.36.66-.7 1.16-1.07 1.74-1.62 1.86-2.1a1.67 1.67 0 0 0-.55-1.68c-.39-.32-1.16-.4-2.72-.58a3.66 3.66 0 0 1-.87-.15c-.25-.1-.48-.26-.66-.46a3.75 3.75 0 0 1-.45-.78Z"
                      fill="#0A0C0F"
                      opacity=".4"
                    ></path>
                  </svg>
                  <h1 className="text-gray-300 text-2xl font-medium mb-2 mt-4">
                    Bronze
                  </h1>
                  <div className="text-gray-400">
                    <div className="space-y-3">
                      <p>
                        Maximum of 5 strategies: Offers increased flexiblity by
                        allowing users to execute up to 5 trading strategies.
                      </p>

                      <p>
                        Minimum investment of $5000: Provides an opportunity for
                        users with a larger starting capital to access more
                        strategies.
                      </p>
                      <p>
                        Enhanced Portfolio Management: Allows users to allocate
                        their capital across a wider range of strategies,
                        potentially increasing their overall returns.
                      </p>
                    </div>
                  </div>
                  <div className="w-full">
                    {user?._type === "Bronze" ? (
                      <div className="flex space-x-2 text-green-400 items-center mt-5">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-4 h-4"
                          >
                            <path
                              fillRule="evenodd"
                              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <div>
                          <h1 className="font-medium">Current</h1>
                        </div>
                      </div>
                    ) : (
                      <Link to="/boost-tier">
                        <button className="py-1 w-32 bg-[#b2ffce] text-gray-800 rounded-md mx-auto mt-6 text-sm">
                          Upgrade
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
                <div
                  className={`p-4 rounded-xl ${
                    user?._type === "Silver"
                      ? "bg-green-700 bg-opacity-20"
                      : "bg-[#0a0c0f]"
                  }`}
                >
                  <svg fill="none" viewBox="0 0 32 32" width="24" height="24">
                    <rect
                      width="32"
                      height="32"
                      rx="16"
                      fill="url(#paint0_radial_14470_83669-979578)"
                    ></rect>
                    <path
                      d="M18.37 10.96c-.75-1.5-1.13-2.26-1.62-2.5a1.67 1.67 0 0 0-1.5 0c-.5.24-.87 1-1.62 2.5-.2.41-.31.62-.45.78-.18.2-.4.36-.66.46-.2.08-.43.1-.87.15-1.56.17-2.33.26-2.72.58-.5.4-.7 1.06-.55 1.68.12.48.7 1.03 1.86 2.1.36.34.54.51.66.7.19.3.27.65.25 1-.02.22-.1.45-.26.9-.52 1.5-.79 2.25-.69 2.7.16.72.76 1.26 1.49 1.32.47.04 1.18-.31 2.6-1.02l.52-.27c.43-.21.65-.32.88-.37.2-.03.41-.03.62 0 .23.05.45.16.88.37l.53.27c1.41.7 2.12 1.06 2.6 1.02.72-.06 1.32-.6 1.48-1.31.1-.46-.17-1.2-.7-2.7-.15-.46-.23-.7-.25-.92-.02-.34.06-.7.25-.99.12-.19.3-.36.66-.7 1.16-1.07 1.74-1.62 1.86-2.1a1.67 1.67 0 0 0-.55-1.68c-.39-.32-1.16-.4-2.72-.58a3.66 3.66 0 0 1-.87-.15c-.25-.1-.48-.26-.66-.46a3.75 3.75 0 0 1-.45-.78Z"
                      fill="#0A0C0F"
                      opacity=".4"
                    ></path>
                    <defs>
                      <radialGradient
                        id="paint0_radial_14470_83669-979578"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="matrix(0 23 -23 0 16 9)"
                      >
                        <stop stop-color="#DAE1EF"></stop>
                        <stop offset="1" stop-color="#B6C2D9"></stop>
                      </radialGradient>
                    </defs>
                  </svg>
                  <h1 className="text-gray-300 text-2xl font-medium mb-2 mt-4">
                    Silver
                  </h1>
                  <div className="text-gray-400">
                    <ul className="space-y-3">
                      <li>
                        Maximum of 8 strategies: Provides users with even
                        greater strategy selection, allowing for more
                        diversified trading approaches.
                      </li>
                      <li>
                        Minimum investment of $20,000: Targets users with a
                        higher starting capital, enabling them to explore a
                        larger variety of strategies.
                      </li>
                      <li>
                        Advanced Risk Management: Offers increased risk
                        management capabilities by spreading the investment
                        across multiple strategies, reducing reliance on any
                        single strategy's performance.
                      </li>
                    </ul>
                  </div>
                  <div className="w-full">
                    {user?._type === "Silver" ? (
                      <div className="flex space-x-2 text-green-400 items-center mt-5">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-4 h-4"
                          >
                            <path
                              fillRule="evenodd"
                              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <div>
                          <h1 className="font-medium">Current</h1>
                        </div>
                      </div>
                    ) : (
                      <Link to="/boost-tier">
                        <button className="py-1 w-32 bg-[#b2ffce] text-gray-800 rounded-md mx-auto mt-6 text-sm">
                          Upgrade
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
                <div
                  className={`p-4 rounded-xl ${
                    user?._type === "Gold"
                      ? "bg-green-700 bg-opacity-20"
                      : "bg-[#0a0c0f]"
                  }`}
                >
                  <svg fill="none" viewBox="0 0 32 32" width="24" height="24">
                    <rect
                      width="32"
                      height="32"
                      rx="16"
                      fill="url(#paint0_radial_14470_83671-389511)"
                    ></rect>
                    <path
                      d="M18.37 10.96c-.75-1.5-1.13-2.26-1.62-2.5a1.67 1.67 0 0 0-1.5 0c-.5.24-.87 1-1.62 2.5-.2.41-.31.62-.45.78-.18.2-.4.36-.66.46-.2.08-.43.1-.87.15-1.56.17-2.33.26-2.72.58-.5.4-.7 1.06-.55 1.68.12.48.7 1.03 1.86 2.1.36.34.54.51.66.7.19.3.27.65.25 1-.02.22-.1.45-.26.9-.52 1.5-.79 2.25-.69 2.7.16.72.76 1.26 1.49 1.32.47.04 1.18-.31 2.6-1.02l.52-.27c.43-.21.65-.32.88-.37.2-.03.41-.03.62 0 .23.05.45.16.88.37l.53.27c1.41.7 2.12 1.06 2.6 1.02.72-.06 1.32-.6 1.48-1.31.1-.46-.17-1.2-.7-2.7-.15-.46-.23-.7-.25-.92-.02-.34.06-.7.25-.99.12-.19.3-.36.66-.7 1.16-1.07 1.74-1.62 1.86-2.1a1.67 1.67 0 0 0-.55-1.68c-.39-.32-1.16-.4-2.72-.58a3.66 3.66 0 0 1-.87-.15c-.25-.1-.48-.26-.66-.46a3.75 3.75 0 0 1-.45-.78Z"
                      fill="#0A0C0F"
                      opacity=".4"
                    ></path>
                    <defs>
                      <radialGradient
                        id="paint0_radial_14470_83671-389511"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="matrix(0 23 -23 0 16 9)"
                      >
                        <stop stop-color="#FFDB80"></stop>
                        <stop offset="1" stop-color="#FFC24D"></stop>
                      </radialGradient>
                    </defs>
                  </svg>
                  <h1 className="text-gray-300 text-2xl font-medium mb-2 mt-4">
                    Gold
                  </h1>
                  <div className="text-gray-400">
                    <ul className="space-y-3">
                      <li>
                        Maximum of 15+ strategies: Offers the highest level of
                        strategy selection and diversification, with the ability
                        to execute more than 15 trading strategies.
                      </li>
                      <li>
                        Minimum investment of $50,000: Targets experienced users
                        or institutional investors who have a significant
                        capital allocation for algorithmic trading.
                      </li>
                    </ul>
                  </div>
                  <div className="w-full">
                    {user?._type === "Gold" ? (
                      <div className="flex space-x-2 text-green-400 items-center mt-5">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-4 h-4"
                          >
                            <path
                              fillRule="evenodd"
                              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <div>
                          <h1 className="font-medium">Current</h1>
                        </div>
                      </div>
                    ) : (
                      <Link to="/boost-tier">
                        <button className="py-1 w-32 bg-[#b2ffce] text-gray-800 rounded-md mx-auto mt-6 text-sm">
                          Upgrade
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <h2 className="text-gray-400 text-center text-sm">
                  Algoo Strategies is constantly updating and adding new
                  strategies to their platform, so the number of strategies
                  available for each account tier may change over time.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tiers;
