import React from "react";

export default function Badge({ user }) {
  return user._type === "Bronze" ? (
    <svg fill="none" viewBox="0 0 32 32" width="24" height="24">
      <rect width="32" height="32" rx="16" fill="#FFA858"></rect>
      <path
        d="M18.37 10.96c-.75-1.5-1.13-2.26-1.62-2.5a1.67 1.67 0 0 0-1.5 0c-.5.24-.87 1-1.62 2.5-.2.41-.31.62-.45.78-.18.2-.4.36-.66.46-.2.08-.43.1-.87.15-1.56.17-2.33.26-2.72.58-.5.4-.7 1.06-.55 1.68.12.48.7 1.03 1.86 2.1.36.34.54.51.66.7.19.3.27.65.25 1-.02.22-.1.45-.26.9-.52 1.5-.79 2.25-.69 2.7.16.72.76 1.26 1.49 1.32.47.04 1.18-.31 2.6-1.02l.52-.27c.43-.21.65-.32.88-.37.2-.03.41-.03.62 0 .23.05.45.16.88.37l.53.27c1.41.7 2.12 1.06 2.6 1.02.72-.06 1.32-.6 1.48-1.31.1-.46-.17-1.2-.7-2.7-.15-.46-.23-.7-.25-.92-.02-.34.06-.7.25-.99.12-.19.3-.36.66-.7 1.16-1.07 1.74-1.62 1.86-2.1a1.67 1.67 0 0 0-.55-1.68c-.39-.32-1.16-.4-2.72-.58a3.66 3.66 0 0 1-.87-.15c-.25-.1-.48-.26-.66-.46a3.75 3.75 0 0 1-.45-.78Z"
        fill="#0A0C0F"
        opacity=".4"
      ></path>
    </svg>
  ) : user._type === "Silver" ? (
    <svg fill="none" viewBox="0 0 32 32" width="24" height="24">
      <rect
        width="32"
        height="32"
        rx="16"
        fill="url(#paint0_radial_14470_83669-979578)"
      ></rect>
      <path
        d="M18.37 10.96c-.75-1.5-1.13-2.26-1.62-2.5a1.67 1.67 0 0 0-1.5 0c-.5.24-.87 1-1.62 2.5-.2.41-.31.62-.45.78-.18.2-.4.36-.66.46-.2.08-.43.1-.87.15-1.56.17-2.33.26-2.72.58-.5.4-.7 1.06-.55 1.68.12.48.7 1.03 1.86 2.1.36.34.54.51.66.7.19.3.27.65.25 1-.02.22-.1.45-.26.9-.52 1.5-.79 2.25-.69 2.7.16.72.76 1.26 1.49 1.32.47.04 1.18-.31 2.6-1.02l.52-.27c.43-.21.65-.32.88-.37.2-.03.41-.03.62 0 .23.05.45.16.88.37l.53.27c1.41.7 2.12 1.06 2.6 1.02.72-.06 1.32-.6 1.48-1.31.1-.46-.17-1.2-.7-2.7-.15-.46-.23-.7-.25-.92-.02-.34.06-.7.25-.99.12-.19.3-.36.66-.7 1.16-1.07 1.74-1.62 1.86-2.1a1.67 1.67 0 0 0-.55-1.68c-.39-.32-1.16-.4-2.72-.58a3.66 3.66 0 0 1-.87-.15c-.25-.1-.48-.26-.66-.46a3.75 3.75 0 0 1-.45-.78Z"
        fill="#0A0C0F"
        opacity=".4"
      ></path>
      <defs>
        <radialGradient
          id="paint0_radial_14470_83669-979578"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 23 -23 0 16 9)"
        >
          <stop stop-color="#DAE1EF"></stop>
          <stop offset="1" stop-color="#B6C2D9"></stop>
        </radialGradient>
      </defs>
    </svg>
  ) : user._type === "Gold" ? (
    <svg fill="none" viewBox="0 0 32 32" width="24" height="24">
      <rect
        width="32"
        height="32"
        rx="16"
        fill="url(#paint0_radial_14470_83671-389511)"
      ></rect>
      <path
        d="M18.37 10.96c-.75-1.5-1.13-2.26-1.62-2.5a1.67 1.67 0 0 0-1.5 0c-.5.24-.87 1-1.62 2.5-.2.41-.31.62-.45.78-.18.2-.4.36-.66.46-.2.08-.43.1-.87.15-1.56.17-2.33.26-2.72.58-.5.4-.7 1.06-.55 1.68.12.48.7 1.03 1.86 2.1.36.34.54.51.66.7.19.3.27.65.25 1-.02.22-.1.45-.26.9-.52 1.5-.79 2.25-.69 2.7.16.72.76 1.26 1.49 1.32.47.04 1.18-.31 2.6-1.02l.52-.27c.43-.21.65-.32.88-.37.2-.03.41-.03.62 0 .23.05.45.16.88.37l.53.27c1.41.7 2.12 1.06 2.6 1.02.72-.06 1.32-.6 1.48-1.31.1-.46-.17-1.2-.7-2.7-.15-.46-.23-.7-.25-.92-.02-.34.06-.7.25-.99.12-.19.3-.36.66-.7 1.16-1.07 1.74-1.62 1.86-2.1a1.67 1.67 0 0 0-.55-1.68c-.39-.32-1.16-.4-2.72-.58a3.66 3.66 0 0 1-.87-.15c-.25-.1-.48-.26-.66-.46a3.75 3.75 0 0 1-.45-.78Z"
        fill="#0A0C0F"
        opacity=".4"
      ></path>
      <defs>
        <radialGradient
          id="paint0_radial_14470_83671-389511"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 23 -23 0 16 9)"
        >
          <stop stop-color="#FFDB80"></stop>
          <stop offset="1" stop-color="#FFC24D"></stop>
        </radialGradient>
      </defs>
    </svg>
  ) : (
    <svg fill="none" viewBox="0 0 32 32" width="24" height="24">
      <rect
        width="32"
        height="32"
        rx="16"
        fill="#fff"
        fill-opacity=".16"
      ></rect>
      <path
        d="M18.37 10.96c-.75-1.5-1.13-2.26-1.62-2.5a1.67 1.67 0 0 0-1.5 0c-.5.24-.87 1-1.62 2.5-.2.41-.31.62-.45.78-.18.2-.4.36-.66.46-.2.08-.43.1-.87.15-1.56.17-2.33.26-2.72.58-.5.4-.7 1.06-.55 1.68.12.48.7 1.03 1.86 2.1.36.34.54.51.66.7.19.3.27.65.25 1-.02.22-.1.45-.26.9-.52 1.5-.79 2.25-.69 2.7.16.72.76 1.26 1.49 1.32.47.04 1.18-.31 2.6-1.02l.52-.27c.43-.21.65-.32.88-.37.2-.03.41-.03.62 0 .23.05.45.16.88.37l.53.27c1.41.7 2.12 1.06 2.6 1.02.72-.06 1.32-.6 1.48-1.31.1-.46-.17-1.2-.7-2.7-.15-.46-.23-.7-.25-.92-.02-.34.06-.7.25-.99.12-.19.3-.36.66-.7 1.16-1.07 1.74-1.62 1.86-2.1a1.67 1.67 0 0 0-.55-1.68c-.39-.32-1.16-.4-2.72-.58a3.66 3.66 0 0 1-.87-.15c-.25-.1-.48-.26-.66-.46a3.75 3.75 0 0 1-.45-.78Z"
        fill="#fff"
        fill-opacity=".36"
      ></path>
    </svg>
  );
}

export function NavBadge({ user }) {
  return user._type === "Bronze" ? (
    <svg fill="none" viewBox="0 0 32 32" width="24" height="24">
      <rect width="32" height="32" rx="16" fill="#FFA858"></rect>
      <path
        d="M18.37 10.96c-.75-1.5-1.13-2.26-1.62-2.5a1.67 1.67 0 0 0-1.5 0c-.5.24-.87 1-1.62 2.5-.2.41-.31.62-.45.78-.18.2-.4.36-.66.46-.2.08-.43.1-.87.15-1.56.17-2.33.26-2.72.58-.5.4-.7 1.06-.55 1.68.12.48.7 1.03 1.86 2.1.36.34.54.51.66.7.19.3.27.65.25 1-.02.22-.1.45-.26.9-.52 1.5-.79 2.25-.69 2.7.16.72.76 1.26 1.49 1.32.47.04 1.18-.31 2.6-1.02l.52-.27c.43-.21.65-.32.88-.37.2-.03.41-.03.62 0 .23.05.45.16.88.37l.53.27c1.41.7 2.12 1.06 2.6 1.02.72-.06 1.32-.6 1.48-1.31.1-.46-.17-1.2-.7-2.7-.15-.46-.23-.7-.25-.92-.02-.34.06-.7.25-.99.12-.19.3-.36.66-.7 1.16-1.07 1.74-1.62 1.86-2.1a1.67 1.67 0 0 0-.55-1.68c-.39-.32-1.16-.4-2.72-.58a3.66 3.66 0 0 1-.87-.15c-.25-.1-.48-.26-.66-.46a3.75 3.75 0 0 1-.45-.78Z"
        fill="#0A0C0F"
        opacity=".4"
      ></path>
    </svg>
  ) : user._type === "Silver" ? (
    <svg fill="none" viewBox="0 0 32 32" width="24" height="24">
      <rect
        width="32"
        height="32"
        rx="16"
        fill="url(#paint0_radial_14470_83669-979578)"
      ></rect>
      <path
        d="M18.37 10.96c-.75-1.5-1.13-2.26-1.62-2.5a1.67 1.67 0 0 0-1.5 0c-.5.24-.87 1-1.62 2.5-.2.41-.31.62-.45.78-.18.2-.4.36-.66.46-.2.08-.43.1-.87.15-1.56.17-2.33.26-2.72.58-.5.4-.7 1.06-.55 1.68.12.48.7 1.03 1.86 2.1.36.34.54.51.66.7.19.3.27.65.25 1-.02.22-.1.45-.26.9-.52 1.5-.79 2.25-.69 2.7.16.72.76 1.26 1.49 1.32.47.04 1.18-.31 2.6-1.02l.52-.27c.43-.21.65-.32.88-.37.2-.03.41-.03.62 0 .23.05.45.16.88.37l.53.27c1.41.7 2.12 1.06 2.6 1.02.72-.06 1.32-.6 1.48-1.31.1-.46-.17-1.2-.7-2.7-.15-.46-.23-.7-.25-.92-.02-.34.06-.7.25-.99.12-.19.3-.36.66-.7 1.16-1.07 1.74-1.62 1.86-2.1a1.67 1.67 0 0 0-.55-1.68c-.39-.32-1.16-.4-2.72-.58a3.66 3.66 0 0 1-.87-.15c-.25-.1-.48-.26-.66-.46a3.75 3.75 0 0 1-.45-.78Z"
        fill="#0A0C0F"
        opacity=".4"
      ></path>
      <defs>
        <radialGradient
          id="paint0_radial_14470_83669-979578"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 23 -23 0 16 9)"
        >
          <stop stop-color="#DAE1EF"></stop>
          <stop offset="1" stop-color="#B6C2D9"></stop>
        </radialGradient>
      </defs>
    </svg>
  ) : user._type === "Gold" ? (
    <svg fill="none" viewBox="0 0 32 32" width="24" height="24">
      <rect
        width="32"
        height="32"
        rx="16"
        fill="url(#paint0_radial_14470_83671-389511)"
      ></rect>
      <path
        d="M18.37 10.96c-.75-1.5-1.13-2.26-1.62-2.5a1.67 1.67 0 0 0-1.5 0c-.5.24-.87 1-1.62 2.5-.2.41-.31.62-.45.78-.18.2-.4.36-.66.46-.2.08-.43.1-.87.15-1.56.17-2.33.26-2.72.58-.5.4-.7 1.06-.55 1.68.12.48.7 1.03 1.86 2.1.36.34.54.51.66.7.19.3.27.65.25 1-.02.22-.1.45-.26.9-.52 1.5-.79 2.25-.69 2.7.16.72.76 1.26 1.49 1.32.47.04 1.18-.31 2.6-1.02l.52-.27c.43-.21.65-.32.88-.37.2-.03.41-.03.62 0 .23.05.45.16.88.37l.53.27c1.41.7 2.12 1.06 2.6 1.02.72-.06 1.32-.6 1.48-1.31.1-.46-.17-1.2-.7-2.7-.15-.46-.23-.7-.25-.92-.02-.34.06-.7.25-.99.12-.19.3-.36.66-.7 1.16-1.07 1.74-1.62 1.86-2.1a1.67 1.67 0 0 0-.55-1.68c-.39-.32-1.16-.4-2.72-.58a3.66 3.66 0 0 1-.87-.15c-.25-.1-.48-.26-.66-.46a3.75 3.75 0 0 1-.45-.78Z"
        fill="#0A0C0F"
        opacity=".4"
      ></path>
      <defs>
        <radialGradient
          id="paint0_radial_14470_83671-389511"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 23 -23 0 16 9)"
        >
          <stop stop-color="#FFDB80"></stop>
          <stop offset="1" stop-color="#FFC24D"></stop>
        </radialGradient>
      </defs>
    </svg>
  ) : (
    <svg fill="none" viewBox="0 0 32 32" width="24" height="24">
      <rect
        width="32"
        height="32"
        rx="16"
        fill="#fff"
        fill-opacity=".16"
      ></rect>
      <path
        d="M18.37 10.96c-.75-1.5-1.13-2.26-1.62-2.5a1.67 1.67 0 0 0-1.5 0c-.5.24-.87 1-1.62 2.5-.2.41-.31.62-.45.78-.18.2-.4.36-.66.46-.2.08-.43.1-.87.15-1.56.17-2.33.26-2.72.58-.5.4-.7 1.06-.55 1.68.12.48.7 1.03 1.86 2.1.36.34.54.51.66.7.19.3.27.65.25 1-.02.22-.1.45-.26.9-.52 1.5-.79 2.25-.69 2.7.16.72.76 1.26 1.49 1.32.47.04 1.18-.31 2.6-1.02l.52-.27c.43-.21.65-.32.88-.37.2-.03.41-.03.62 0 .23.05.45.16.88.37l.53.27c1.41.7 2.12 1.06 2.6 1.02.72-.06 1.32-.6 1.48-1.31.1-.46-.17-1.2-.7-2.7-.15-.46-.23-.7-.25-.92-.02-.34.06-.7.25-.99.12-.19.3-.36.66-.7 1.16-1.07 1.74-1.62 1.86-2.1a1.67 1.67 0 0 0-.55-1.68c-.39-.32-1.16-.4-2.72-.58a3.66 3.66 0 0 1-.87-.15c-.25-.1-.48-.26-.66-.46a3.75 3.75 0 0 1-.45-.78Z"
        fill="#fff"
        fill-opacity=".36"
      ></path>
    </svg>
  );
}
