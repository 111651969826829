import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import Loader from "../../components/Loader";
import SidebarComponent from "../../components/navigation/Sidebar";
import Chart from "../../components/charts/Chart";
import InvestModal from "../../components/modals/InvestModal";
import PieChart from "../../components/charts/PieChart";
import QuestionMark, { HelperTooltip } from "../../components/lib/QuestionMark";
import ReactMarkdown from "react-markdown";
import client from "../../utils/client";
import { userTiers } from ".";

const Pool = () => {
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [pool, setPool] = useState(null);
  const [showTooltip, setShowTooltip] = useState(0);
  const [rank, setRank] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [investmentStatus, setInvestmentStatus] = useState(false);
  useEffect(() => {
    client({
      url: `/txns/pool/${id}`,
      method: "GET",
    })
      .then((poolData) => {
        client({
          url: `/txns/pools`,
          method: "GET",
        })
          .then((res) => {
            const names = res.data.data.map((data) => data.name);
            const strategy = names.indexOf(poolData.data.data?.name);
            setRank(strategy);
            setPool(poolData.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  return (
    <div>
      <div>
        <Loader />
      </div>

      <div>
        <SidebarComponent user={user} />
      </div>
      <div className="p-4 lg:ml-64 bg-[#0a0c0f] min-h-screen">
        <div className="p-2 border border-gray-600 min-h-screen border-dashed rounded-lg dark:border-gray-700 mt-20">
          {investmentStatus && <InvestModal poolId={pool?._id} />}
          {pool && (
            <div className="md:px-5">
              <h1 className="text-gray-300 text-2xl md:text-4xl my-9 text-center font-medium tracking-wide">
                {pool.name}
              </h1>
              <div className="lg:grid grid-cols-2 gap-3">
                <div className="flex items-center space-x-3 text-gray-400">
                  <div className="w-[15%]">
                    <img
                      src={pool.image}
                      className="w-full rounded-full"
                      alt="Manager_pic"
                    />
                  </div>
                  <div className="">
                    <h1>
                      Managed by:{" "}
                      <span className="font-medium">{pool.manager}</span>
                    </h1>
                    <p>Holds {pool.managerHolding} % of pool</p>
                  </div>
                </div>
                <div className="bg-[#181b20] px-4 rounded-lg py-5 grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-2 gap-4 max-w-full max-lg:mt-5">
                  <div>
                    <p className="mb-1 text-sm text-gray-400">Depositors</p>
                    <h1 className="text-lg font-medium tracking-wider text-gray-300">
                      {pool.investors}
                    </h1>
                  </div>
                  <div className="">
                    <p className="mb-1 text-sm text-gray-400">Value Managed</p>
                    <h1 className="text-lg font-medium tracking-wider text-gray-300">
                      {pool.valueManaged}
                    </h1>
                  </div>
                  <div className="">
                    <div className="flex items-center relative">
                      <p className="mb-1 text-sm text-gray-400 mr-1">
                        Yearly Return
                      </p>
                      <div
                        onMouseEnter={() => {
                          setShowTooltip(1);
                        }}
                        onMouseLeave={() => {
                          setShowTooltip(0);
                        }}
                      >
                        <QuestionMark />
                      </div>
                      {showTooltip === 1 && (
                        <HelperTooltip message="Includes fees taken (fee-adjusted)" />
                      )}
                    </div>
                    <h1
                      className={`text-lg font-medium tracking-wider ${
                        pool.yearlyReturn.includes("-")
                          ? "text-red-500"
                          : "text-[#b2ffce]"
                      }`}
                    >
                      {!pool.yearlyReturn.includes("-") && "+"}
                      {pool.yearlyReturn} %
                    </h1>
                  </div>
                  <div className="relative">
                    <div className="flex items-center">
                      <p className="mb-1 text-[14px] text-gray-400 mr-1">
                        Risk Factor
                      </p>
                      <div
                        onMouseEnter={() => {
                          setShowTooltip(2);
                        }}
                        onMouseLeave={() => {
                          setShowTooltip(0);
                        }}
                      >
                        <QuestionMark />
                      </div>
                      {showTooltip === 2 && (
                        <div className="absolute -left-5 -top-24 bg-gray-700 text-gray-300 z-50 w-[12rem] lg:w-[16rem] whitespace-normal rounded-md p-2 text-left text-xs leading-[1.25rem] font-normal shadow-xl">
                          <p className="text-gray-300">
                            Risk Factor is determined by a Strategie's downside
                            volatility. Strategies that have a low Risk Factor
                            translates to smaller downside volatility.
                          </p>
                        </div>
                      )}
                    </div>

                    <h1
                      className={`text-lg font-medium tracking-wider ${
                        pool.riskFactor.split("/")[0] >= 2
                          ? "text-red-500"
                          : "text-[#b2ffce]"
                      }`}
                    >
                      {pool.riskFactor}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="lg:grid grid-cols-2 mt-8 gap-4">
                <div>
                  <Chart
                    boxHeight={400}
                    yearly={pool.weeklyReturn}
                    monthly={pool.dailyReturn}
                  />
                  <div className="rounded-b-lg shadow-2xl py-5">
                    <div className="max-lg:grid grid-cols-3 sm:grid-cols-4 lg:flex lg:items-center w-full lg:justify-evenly">
                      <div>
                        <p className="text-gray-400 text-sm mb-1">Daily:</p>
                        <h2
                          className={`text-lg ${
                            pool.dailyReturn.includes("-")
                              ? "text-red-500"
                              : "text-[#b2ffce]"
                          }`}
                        >
                          {!pool.dailyReturn.includes("-") && "+"}
                          {pool.dailyReturn} %
                        </h2>
                      </div>
                      <div>
                        <p className="text-gray-400 text-sm mb-1">Weekly:</p>
                        <h2
                          className={`text-lg ${
                            pool.weeklyReturn.includes("-")
                              ? "text-red-500"
                              : "text-[#b2ffce]"
                          }`}
                        >
                          {!pool.weeklyReturn.includes("-") && "+"}
                          {pool.weeklyReturn} %
                        </h2>
                      </div>
                      <div>
                        <p className="text-gray-400 text-sm mb-1">Monthly:</p>
                        <h2
                          className={`text-lg ${
                            pool.oneMonthReturn.includes("-")
                              ? "text-red-500"
                              : "text-[#b2ffce]"
                          }`}
                        >
                          {!pool.oneMonthReturn.includes("-") && "+"}
                          {pool.oneMonthReturn} %
                        </h2>
                      </div>
                      <div>
                        <p className="text-gray-400 text-sm mb-1">6 Months:</p>
                        <h2
                          className={`text-lg ${
                            pool.sixMonthsReturn.includes("-")
                              ? "text-red-500"
                              : "text-[#b2ffce]"
                          }`}
                        >
                          {!pool.sixMonthsReturn.includes("-") && "+"}
                          {pool.sixMonthsReturn} %
                        </h2>
                      </div>
                      <div>
                        <p className="text-gray-400 text-sm mb-1">Yearly:</p>
                        <h2
                          className={`text-lg ${
                            pool.yearlyReturn.includes("-")
                              ? "text-red-500"
                              : "text-[#b2ffce]"
                          }`}
                        >
                          {!pool.yearlyReturn.includes("-") && "+"}
                          {pool.yearlyReturn} %
                        </h2>
                      </div>
                      <div>
                        <p className="text-gray-400 text-sm mb-1">All:</p>
                        <h2
                          className={`text-lg ${
                            pool.lifetimeReturn.includes("-")
                              ? "text-red-500"
                              : "text-[#b2ffce]"
                          }`}
                        >
                          {!pool.lifetimeReturn.includes("-") && "+"}
                          {pool.lifetimeReturn} %
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="bg-[#181b20] p-3 rounded-lg my-3">
                    <h1 className="text-lg lg:text-xl font-medium mb-3 text-gray-300">
                      Fee Structure
                    </h1>
                    <div className="flex items-center space-x-3">
                      <div>
                        <div className="flex items-center">
                          <p className="mb-1 text-[14px] text-gray-400 mr-1">
                            Performance:
                          </p>
                        </div>

                        <h1
                          className={`text-sm lg:text-lg tracking-wider text-gray-300`}
                        >
                          {pool.performance} %
                        </h1>
                      </div>
                      <div className="relative">
                        <p className="mb-1 text-[14px] text-gray-400 mr-1">
                          Management
                        </p>
                        <div className="flex items-center">
                          <h1
                            className={`text-sm lg:text-lg tracking-wider text-gray-300`}
                          >
                            {pool.managementFee} %
                          </h1>
                          <div
                            onMouseEnter={() => {
                              setShowTooltip(6);
                            }}
                            onMouseLeave={() => {
                              setShowTooltip(0);
                            }}
                          >
                            <QuestionMark />
                          </div>
                          {showTooltip === 6 && (
                            <div className="absolute -left-5 -top-[70px] bg-gray-700 text-gray-300 z-50 w-[12rem] lg:w-[16rem] whitespace-normal rounded-md p-2 text-left text-sm leading-[1.25rem] font-normal shadow-xl">
                              <p className="text-gray-300">
                                An annual fee that will be charged per block,
                                regardless of the vault's performance. Limited
                                to 3% per year maximum.
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="bg-[#181b20] rounded-md shadow-md text-gray-300 p-5">
                    <div>
                      <div className="flex justify-between">
                        <h1 className="mb-5">
                          Account Type:{" "}
                          <span className="text-white font-medium">
                            {pool?.accountType.join(", ")}
                          </span>
                        </h1>
                        <div>
                          <button
                            className="bg-[#b2ffce] text-gray-800 font-medium px-5 py-1 rounded-md"
                            onClick={() => {
                              setInvestmentStatus(!investmentStatus);
                            }}
                          >
                            {pool?.category === "Defi" ? "Invest" : "Trade"}
                          </button>
                        </div>
                      </div>
                      <h1 className="font-medium text-3xl mb-1">About</h1>
                      <ReactMarkdown>
                        {expanded
                          ? pool.about
                          : pool.about.slice(0, 100) + "..."}
                      </ReactMarkdown>
                      <div className="mt-2">
                        {!expanded ? (
                          <button
                            onClick={toggleExpanded}
                            className="text-gray-300 underline"
                          >
                            Read More
                          </button>
                        ) : (
                          <button
                            onClick={toggleExpanded}
                            className="text-gray-300 underline"
                          >
                            Read Less
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="my-5 flex items-center justify-evenly">
                      <a
                        href={pool.strategyWallet}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="bg-[#0a0c0f] hover:cursor-pointer w-fit border border-gray-200 text-gray-300 rounded-full px-2 md:px-3 py-1">
                          <p className="text-xs md:text-sm">Strategy wallet</p>
                        </div>
                      </a>
                      <a
                        href={pool.strategyProsAndCons}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="bg-[#0a0c0f] hover:cursor-pointer w-fit border border-gray-200 text-gray-300 rounded-full px-2 md:px-3 py-1">
                          <p className="text-xs md:text-sm">Pros and cons</p>
                        </div>
                      </a>
                      <a href={pool.details} target="_blank" rel="noreferrer">
                        <div className="bg-[#0a0c0f] hover:cursor-pointer w-fit border border-gray-200 text-gray-300 rounded-full px-2 md:px-3 py-1">
                          <p className="text-xs md:text-sm">More details</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 lg:grid grid-cols-2 gap-5 max-lg:space-y-10">
                <div className="text-gray-300 w-full">
                  <h1 className="font-medium text-xl lg:text-3xl mb-3">
                    Portfolio
                  </h1>
                  <div>
                    <PieChart percentages={pool.portfolio} />
                  </div>
                </div>
                <div className="text-gray-300">
                  <h1 className="font-medium text-xl lg:text-3xl mb-3">
                    {pool.name} Statistics
                  </h1>
                  <div className="grid max-lg:grid-cols-2 gap-4 lg:grid-cols-4">
                    <div>
                      <div className="flex items-center relative">
                        <p className="text-gray-400 text-sm">
                          Total Value Locked AUM:
                        </p>
                        <div
                          onMouseEnter={() => {
                            setShowTooltip(3);
                          }}
                          onMouseLeave={() => {
                            setShowTooltip(0);
                          }}
                        >
                          <QuestionMark />
                        </div>
                        {showTooltip === 3 && (
                          <HelperTooltip message="Represents total supply of the strategy tokens." />
                        )}
                      </div>
                      <h2>$ {pool.totalLockedValue}</h2>
                    </div>
                    <div>
                      <div className="flex items-center relative">
                        <p className="text-gray-400">All Time High:</p>
                        <div
                          onMouseEnter={() => {
                            setShowTooltip(4);
                          }}
                          onMouseLeave={() => {
                            setShowTooltip(0);
                          }}
                        >
                          <QuestionMark />
                        </div>
                        {showTooltip === 4 && (
                          <HelperTooltip message="The highest strategy token price since strategy creation." />
                        )}
                      </div>
                      <h2>{pool.lifetimeReturn} %</h2>
                    </div>
                    <div>
                      <div className="flex items-center relative">
                        <p className="text-gray-400">Leaderboard Rank:</p>
                      </div>
                      <h2>{rank ? rank : "Loading"}</h2>
                    </div>
                    <div>
                      <div className="flex items-center relative">
                        <p className="text-gray-400">Earned Fees:</p>
                        <div
                          onMouseEnter={() => {
                            setShowTooltip(5);
                          }}
                          onMouseLeave={() => {
                            setShowTooltip(0);
                          }}
                        >
                          <QuestionMark />
                        </div>
                        {showTooltip === 5 && (
                          <HelperTooltip message="Total value of manager fees minted." />
                        )}
                      </div>
                      <h2>$ {pool.managementFeesEarned}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Pool);
