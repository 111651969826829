import React from "react";
import {
  referralsOverview,
  referralsOverviewTableHead,
} from "../../utils/helpers";

export default function ReferralStructureOverviewTable() {
  return (
    <div className="no__scroll overflow-x-auto">
      <table className="min-w-full border-0 table-fixed">
        <thead>
          <tr className="bg-gray-800/90 rounded-lg text-left">
            {referralsOverviewTableHead.map((item, index) => (
              <th
                key={index + 0.482}
                className={`border md:w-1/5 border-gray-800/60 p-3 font-medium ${
                  index === 0 ? "text-left rounded-tl-lg" : "text-center"
                } ${
                  index === 3 ? "rounded-tr-lg" : ""
                } sm:text-lg uppercase tracking-wider`}
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {referralsOverview.map((category, index) => (
            <tr
              key={index + 0.34}
              className={`${index % 2 ? "bg-gray-900/70" : ""} border-0`}
            >
              {category.map((item, index) => (
                <td
                  key={index + 0.5}
                  className={`border ${
                    index === 0
                      ? "text-left font-medium uppercase"
                      : "text-center"
                  } border-gray-800/60 p-3`}
                >
                  {item}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
