import { getNextPlanAmount, USER_TYPES } from "../components/lib/constants";
import { formatToCurrency } from "./money";

export function getNextTier(currentTier) {
  switch (currentTier) {
    case USER_TYPES.base:
      return USER_TYPES.bronze;
    case USER_TYPES.bronze:
      return USER_TYPES.silver;
    case USER_TYPES.silver:
      return USER_TYPES.gold;
    case USER_TYPES.gold:
      return "";

    default:
      return "";
  }
}

export function getNextTierReferral(currentTier) {
  switch (currentTier) {
    case USER_TYPES.base:
      return 1;
    case USER_TYPES.bronze:
      return 2;
    case USER_TYPES.silver:
      return 3;
    default:
      return "";
  }
}

export const tierOverviewTableHead = [
  "Tier",
  "Base Tier",
  "Bronze Tier",
  "Silver Tier",
  "Gold Tier",
];

export const referralsOverviewTableHead = [
  "Current Tier",
  "Next Tier",
  "Number of Referrals Tier",
  "Minimum Deposit Required",
];

export const tierOverview = [
  [
    "Number of Strategies",
    "Up to 2 strategies",
    "Up to 5 strategies",
    "Up to 8 strategies",
    "Up to 15 strategies",
  ],
  [
    "Referral Rewards",
    "5% per referral",
    "7% per referral",
    "10% per referral",
    "12% per referral",
  ],

  [
    "APY Boost",
    "Standard APY",
    "+10% APY boost",
    "+15% APY boost",
    "+20% APY boost",
  ],
  [
    "Exclusive Rewards",
    "N/A",
    "Early access to upcoming strategies",
    "VIP strategies with premium assets",
    "VIP strategies, early access, and priority support",
  ],
];

export const referralsOverview = [
  ["Base Tier", "Bronze Tier", "1 referrals", "$5,000"],
  ["Bronze Tier", "Silver Tier", "2 referrals", "$15,000"],
  ["Silver Tier", "Gold Tier", "3 referrals", "$20,000"],
];

export function getTierComparisonData(userTier) {
  let valueIndex = 1;

  switch (userTier) {
    case USER_TYPES.bronze:
      valueIndex = 2;
      break;
    case USER_TYPES.silver:
      valueIndex = 3;
      break;
    case USER_TYPES.gold:
      valueIndex = 4;
      break;

    default:
      valueIndex = 1;
      break;
  }

  let currentTierBenefits = [
    [tierOverview[0][valueIndex], tierOverview[0][valueIndex + 1]],
    [tierOverview[1][valueIndex], tierOverview[1][valueIndex + 1]],
    [tierOverview[2][valueIndex], tierOverview[2][valueIndex + 1]],
    [tierOverview[3][valueIndex], tierOverview[3][valueIndex + 1]],
  ];
  return currentTierBenefits;
}

export const upgradeOptionsData = (userDeposited, userType, userRefers) => [
  {
    title: "By deposit",
    text: `Deposit ${formatToCurrency(
      getNextPlanAmount(userType, userDeposited)
    )} more to unlock the ${getNextTier(userType)} Tier`,
    buttonText: "Deposit now",
    href: "/deposit",
    value: "deposit",
  },
  {
    title: "By referrals",
    text: `Refer ${
      Number(getNextTierReferral(userType)) - Number(userRefers)
    } more users to reach the ${getNextTier(userType)} Tier`,
    buttonText: "Get referral link",
    href: "/profile",
    value: "referral",
  },
];

export const impactOverview = [
  {
    title: "Increased Referral Rewards",
    description:
      "Through referrals, users can earn significantly higher rewards—starting at 5% and scaling up to 12% in the Gold Tier.",
  },
  {
    title: "More Strategies",
    description:
      "As you move up the tiers, you gain access to additional strategies—doubling your options for diversification and potential returns.",
  },
  {
    title: "Higher APY",
    description:
      "Boosting APY across tiers means your investments are working harder for you, growing faster with each upgrade.",
  },
  {
    title: "Exclusive Banefits",
    description:
      "The Gold Tier brings you into Algoo's elite circle, offering priority support and VIP strategies, giving you the tools and guidance needed to maximize your earnings.",
  },
];
