import React from "react";
import { getNextTier, getTierComparisonData } from "../../utils/helpers";

export default function TiersComparisonTable({ userType }) {
  const header = [
    `${userType} Tier (current)`,
    `${getNextTier(userType)} Tier (next)`,
  ];

  const currentTierBenefits = getTierComparisonData(userType);

  function tableItem(item, index) {
    return (
      <td
        key={index + 0.5}
        className={`border text-center border-gray-800/60 p-3`}
      >
        {item}
      </td>
    );
  }
  return (
    <div className="no__scroll overflow-x-auto">
      <table className="min-w-full border-0 table-fixed">
        <thead>
          <tr className="bg-gray-800/90 rounded-lg text-left">
            {header.map((item, index) => (
              <th
                key={index + 0.482}
                className={`border w-[400px] md:w-1/5 border-gray-800/60 text-center p-3 font-medium ${
                  index === 0 ? "rounded-tl-lg" : "rounded-tr-lg"
                } text-lg uppercase tracking-wider`}
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentTierBenefits.map((category) => (
            <tr>{category.map((item, index) => tableItem(item, index))} </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
