import React from "react";
import { tierOverview, tierOverviewTableHead } from "../../utils/helpers";

export default function TiersOverviewBenefitsTable() {
  return (
    <div className="no__scroll overflow-x-auto">
      <table className="min-w-full border-0 table-fixed">
        <thead>
          <tr className="bg-gray-800/90 rounded-lg text-left">
            {tierOverviewTableHead.map((item, index) => (
              <th
                key={index + 0.482}
                className={`border w-[400px] md:w-1/5 border-gray-800/60 p-3 font-medium ${
                  index === 0 ? "text-left rounded-tl-lg" : "text-center"
                } ${
                  index === 4 ? "rounded-tr-lg" : ""
                } sm:text-lg uppercase tracking-wider`}
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tierOverview.map((category, index) => (
            <tr
              key={index + 0.34}
              className={`${index % 2 ? "bg-gray-900/60" : ""} border-0`}
            >
              {category.map((item, index) => (
                <td
                  style={{
                    transform: "translate3d(6px, -6px, 0)",
                    boxShadow:
                      "#7F7C21 -1px 1px, #7F7C21 -2px 2px, #7F7C21 -3px 3px, #7F7C21 -4px 4px, #7F7C21 -5px 5px, #7F7C21 -6px 6px",
                  }}
                  key={index + 0.5}
                  className={`border ${
                    index === 0
                      ? "text-left font-medium uppercase"
                      : "text-center"
                  } border-gray-800/60 p-3 hover:tranlate `}
                >
                  {item}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
