import React, { useContext } from "react";
import SidebarComponent from "../../components/navigation/Sidebar";
import Loader from "../../components/Loader";
import { AuthContext } from "../../context/authContext";
import Badge from "../../components/lib/Badge";
import { formatToCurrency } from "../../utils/money";
import {
  getNextPlanAmount,
  getNextPlanGoal,
  USER_TYPES,
} from "../../components/lib/constants";
import { getNextTier, getNextTierReferral } from "../../utils/helpers";
import TiersOverviewBenefitsTable from "../../components/tables/TiersOverviewBenefitsTable";
import ReferralStructureOverviewTable from "../../components/tables/ReferralStructureOverview";
import { useNavigate } from "react-router-dom";
import Tooltip from "../../components/lib/Tooltip";
import useUser from "../../hooks/useUser";

export default function BoostTier() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { realTimeUser } = useUser();
  const nextPlanAmount = getNextPlanAmount(
    realTimeUser?._type,
    realTimeUser?.deposited
  );
  const progress = Math.round(
    (Number(realTimeUser?.deposited) / getNextPlanGoal(realTimeUser?._type)) *
      100
  );
  const nextTier = getNextTier(realTimeUser?._type);
  console.log({ realTimeUser });
  console.log({ user });
  return (
    <div className="text-gray-300 tracking-wide">
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={user} />
      </div>
      <div className="p-4 lg:ml-64 bg-[#0a0c0f] min-h-screen">
        <div className="p-4 border border-gray-600 border-dashed min-h-screen rounded-lg dark:border-gray-700 mt-20">
          <div className="flex items-center gap-x-3">
            <h1 className="text-2xl md:text-3xl font-medium">
              Your Boost Tier Status
            </h1>
            {realTimeUser && (
              <div>
                <Badge user={realTimeUser} />
              </div>
            )}
          </div>
          <div className="mt-3">
            <div className="max-sm:gap-y-6 max-sm:flex-col flex sm:items-center gap-x-5 border-b border-gray-800/70 py-4 sm:py-8">
              <div>
                <h3 className="font-medium text-lg">
                  Current Tier —{"   "}
                  <span className="font-semibold text-xl pl-2">
                    {realTimeUser?._type ?? ""}
                  </span>
                </h3>
                <h4>Deposits: {formatToCurrency(realTimeUser?.deposited)}</h4>
              </div>
              <div>
                <Tooltip text={`Your progress to the next tier - ${progress}%`}>
                  <div className="w-60 bg-gray-600/50 rounded-full">
                    <div
                      style={{
                        width:
                          Number(realTimeUser?.deposited) === nextPlanAmount
                            ? "100%"
                            : `${progress}%`,
                      }}
                      className="h-3 rounded-full bg-gradient-to-r from-purple-500 via-[#b2ffce] to-purple-400  animate-gradient-bg bg-200%"
                    ></div>
                  </div>
                  <div className="flex items-center justify-between">
                    <h2>0%</h2>
                    <h2>100%</h2>
                  </div>
                </Tooltip>
              </div>
              {realTimeUser?._type !== USER_TYPES.gold ? (
                <div>
                  <h3 className="font-medium text-lg">
                    Next Tier —{""}
                    <span className="font-semibold text-xl pl-2">
                      {nextTier}
                    </span>{" "}
                    <span className="font-semibold">
                      ({formatToCurrency(nextPlanAmount)} more)
                    </span>
                  </h3>
                  <h4>
                    Deposit an additional {formatToCurrency(nextPlanAmount)} or
                    refer{" "}
                    {Number(getNextTierReferral(realTimeUser?._type)) -
                      realTimeUser?.refers.length}{" "}
                    more users to reach {nextTier} Tier
                  </h4>
                </div>
              ) : null}
            </div>
            <div className="mt-5">
              <div>
                <h2 className="text-xl font-medium mb-4">
                  Reward Structure By Tier
                </h2>
                <TiersOverviewBenefitsTable />
              </div>
              <div className="mt-14">
                <h2 className="text-xl font-medium mb-4">
                  Upgrade Requirements: Referral Structure
                </h2>
                <ReferralStructureOverviewTable />
              </div>
            </div>
            {realTimeUser?._type !== USER_TYPES.gold ? (
              <div className="max-sm:flex-col-reverse max-sm:gap-y-4 flex justify-end mt-10 pb-6 gap-x-5">
                <button
                  onClick={() => navigate("/profile")}
                  className="button font-medium"
                >
                  View referral progress
                </button>
                <button
                  onClick={() => navigate("/upgrade-tier")}
                  className="button font-medium"
                >
                  Upgrade my tier
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
