export const constants = {
  BASE_MIN_DEPOSIT: 500,
  BRONZE_MIN_DEPOSIT: 5000,
  SILVER_MIN_DEPOSIT: 15000,
  GOLD_MIN_DEPOSIT: 20000,
};

export const USER_TYPES = {
  base: "Base",
  bronze: "Bronze",
  silver: "Silver",
  gold: "Gold",
};

export const getNextPlanAmount = (userType, userDeposits) => {
  switch (userType) {
    case USER_TYPES.base:
      return constants.BRONZE_MIN_DEPOSIT - Number(userDeposits);
    case USER_TYPES.bronze:
      return constants.SILVER_MIN_DEPOSIT - Number(userDeposits);
    case USER_TYPES.silver:
      return constants.GOLD_MIN_DEPOSIT - Number(userDeposits);
    case USER_TYPES.gold:
      return 0;

    default:
      return 0;
  }
};

export const getNextPlanGoal = (userType) => {
  switch (userType) {
    case USER_TYPES.base:
      return constants.BRONZE_MIN_DEPOSIT;
    case USER_TYPES.bronze:
      return constants.SILVER_MIN_DEPOSIT;
    case USER_TYPES.silver:
      return constants.GOLD_MIN_DEPOSIT;
    case USER_TYPES.gold:
      return 0;

    default:
      return 0;
  }
};
