/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import SidebarComponent from "../../components/navigation/Sidebar";
import Withdraw from "../../components/withdraw/withdraw";
import Loader from "../../components/Loader";
import DepositHistoryComponent from "../../components/tables/DepositHistory";
import InvestmentTable from "../../components/tables/InvestmentTable";
import DashboardFooter from "../../components/navigation/DashboardFooter";
import client from "../../utils/client";
import TiersModal from "../../components/modals/TiersModal";
import KYCModal from "../../components/modals/KYCModal";
import { Alert, Snackbar } from "@mui/material";
import check from "../../assets/check.svg";
import { formatToCurrency } from "../../utils/money";
import { DollarCircle, MoneyAdd, ProfileCircle, StatusUp } from "iconsax-react";
import useUser from "../../hooks/useUser";

export const userTiers = {
  base: "Tier 1",
  bronze: "Tier 2",
  silver: "Tier 3",
  gold: "Tier 4",
};

const Dashboard = () => {
  const [withdraw, setWithdraw] = useState(false);
  // const [realTimeUser, setRealTimeUser] = useState(null);
  const [upgrade, setUpgrade] = useState(false);
  const [investments, setInvestments] = useState([]);
  const [investmentsAmount, setInvestmentsAmount] = useState(0);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { realTimeUser } = useUser();

  async function getInvestments() {
    try {
      const { data } = await client({
        url: `/txns/investment?id=${user._id}`,
        method: "GET",
      });

      setInvestments(data.data);
      const activeInvestments = data.data.filter(
        (investment) => investment.status === "active" && investment.amount
      );
      const investmentAccumulator = activeInvestments
        .map((investment) => investment.amount)
        .reduce((prev, curr) => parseInt(prev) + parseInt(curr), 0);

      setInvestmentsAmount(investmentAccumulator);
    } catch (error) {
      console.log(error);
    }
  }
  // function getUserDetails() {
  //   client({ url: `/auth/echo?email=${user.email}`, method: "GET" })
  //     .then((res) => {
  //       setRealTimeUser(res.data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  useEffect(() => {
    getInvestments();
    // getUserDetails();
  }, []);

  return (
    <div>
      {!realTimeUser && (
        <div>
          <Loader />
        </div>
      )}
      {realTimeUser && investments && (
        <div>
          <div>
            <SidebarComponent user={realTimeUser ?? {}} />
          </div>
          {!realTimeUser?.verified && <KYCModal />}
          <div className="p-4 lg:ml-64 bg-[#0a0c0f] min-h-screen">
            <div className="p-4 border border-gray-600 border-dashed min-h-[90vh] rounded-lg dark:border-gray-700 mt-20">
              <div className="lg:grid max-lg:space-y-5 grid-cols-3 gap-4 mb-4">
                <div className="rounded bg-[#181b20] p-5">
                  <h1 className="text-2xl font-medium text-gray-300">
                    Balance
                  </h1>
                  <p className="mt-2 text-lg text-gray-400">
                    {formatToCurrency(Number(realTimeUser?.balance))}
                  </p>
                </div>
                <div className="rounded bg-[#181b20] p-5">
                  <h1 className="text-2xl font-medium text-gray-300">
                    Investments
                  </h1>
                  <div className="mt-3 flex items-center justify-between">
                    <div>
                      <h2 className="text-gray-400 font-normal text-lg">
                        Active
                      </h2>
                      <p className="mt-1 text-lg text-gray-400">
                        {realTimeUser?.activeInvestments}
                      </p>
                    </div>
                    <div>
                      <h2 className="text-gray-400 font-normal text-lg">
                        Total
                      </h2>
                      <p className="mt-2 text-lg text-gray-400">
                        {formatToCurrency(Number(investmentsAmount))}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="rounded bg-[#181b20] p-5">
                  <div className="flex items-center justify-between">
                    <h1 className="text-2xl font-medium text-gray-300">
                      Withdrawals
                    </h1>
                    <button
                      onClick={() => {
                        setWithdraw(!withdraw);
                      }}
                      className="w-24 text-center bg-[#b2ffce] text-sm rounded-md px-2 py-1 outline-none"
                    >
                      Withdraw
                    </button>
                  </div>
                  {withdraw && (
                    <div className="z-50 relative">
                      <Withdraw />
                    </div>
                  )}
                  <div className="mt-3 flex items-center justify-between">
                    <div>
                      <h2 className="text-gray-400 font-normal text-lg">
                        Requests
                      </h2>
                      <p className="mt-1 text-lg text-gray-400">
                        {realTimeUser?.withdrawals}
                      </p>
                    </div>
                    <div>
                      <h2 className="text-gray-400 font-normal text-lg">
                        Total
                      </h2>
                      <p className="mt-2 text-lg text-gray-400">
                        {formatToCurrency(Number(realTimeUser?.withdrawn))}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 p-5 rounded bg-[#181b20]">
                <h1 className="text-gray-300 mb-5 font-medium text-xl">
                  Finish setting up your account
                </h1>
                <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-4">
                  <div>
                    <Link to="/kyc">
                      <div className="card">
                        <div className="flex items-center space-x-3 sm:justify-center">
                          <div>
                            <ProfileCircle
                              color="#6b7280"
                              width={24}
                              height={24}
                            />
                          </div>

                          <div>
                            <h2 className="text-gray-300 text-sm">
                              Verify your account
                            </h2>
                            <p className="text-gray-400 text-xs">
                              Verify your identity by providing some credentials
                              to secure your account
                            </p>
                          </div>
                        </div>
                        {realTimeUser?.verified && (
                          <img
                            src={check}
                            className="absolute top-2 right-2"
                            width={16}
                            height={16}
                            alt=""
                          />
                        )}
                      </div>
                    </Link>
                  </div>
                  <div
                    className="card"
                    onClick={() => {
                      if (!realTimeUser?.verified) {
                        alert("Please verify your account before depositing!");
                      } else {
                        navigate("/deposit");
                      }
                    }}
                  >
                    <div className="flex items-center space-x-3">
                      <div>
                        <MoneyAdd color="#6b7280" width={24} height={24} />
                      </div>

                      <div>
                        <h2 className="text-gray-300 text-sm">Deposit Funds</h2>
                        <p className="text-gray-400 text-xs">
                          Deposit crypto to invest in strategies on Algoo
                          Strategies
                        </p>
                      </div>
                    </div>
                    {realTimeUser?.balance > 0 && (
                      <img
                        src={check}
                        className="absolute top-2 right-2"
                        width={16}
                        height={16}
                        alt=""
                      />
                    )}
                  </div>
                  <div>
                    <Link to="/dashboard/strategies">
                      <div className="card">
                        <div className="flex items-center space-x-3">
                          <div>
                            <DollarCircle
                              color="#6b7280"
                              width={24}
                              height={24}
                            />
                          </div>

                          <div>
                            <h2 className="text-gray-300 text-sm">
                              Invest in strategies
                            </h2>
                            <p className="text-gray-400 text-xs">
                              Start your investment journey with the available
                              strategies on Algoo Strategies
                            </p>
                          </div>
                        </div>
                        {investments.length > 0 && (
                          <img
                            src={check}
                            className="absolute top-2 right-2"
                            width={16}
                            height={16}
                            alt=""
                          />
                        )}
                      </div>
                    </Link>
                  </div>
                  {realTimeUser?._type !== userTiers.gold && (
                    <div className="p-1 rounded bg-gradient-to-r hover:scale-90 duration-500 from-[#34d399] via-[#b2ffce] to-[#10b981] animate-gradient-bg bg-200%">
                      <div
                        onClick={() => {
                          if (!realTimeUser?.verified) {
                            <Snackbar open={true} autoHideDuration={5000}>
                              <Alert severity="error">
                                Please verify your account before depositing!
                              </Alert>
                            </Snackbar>;
                          } else {
                            navigate("/boost-tier");
                          }
                        }}
                        className="card !p-3 h-full !border-0 bg-[#181b20] hover:scale-100 !rounded-[4px]"
                      >
                        <div className="flex items-center space-x-3">
                          <div>
                            <StatusUp color="#6b7280" width={24} height={24} />
                          </div>

                          <div>
                            <h2 className="text-gray-300 text-sm">
                              Upgrade Account
                            </h2>
                            <p className="text-gray-400 text-xs">
                              Deposit funds to upgrade your account. Your
                              current tier is {realTimeUser?._type}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {upgrade && <TiersModal userTier={realTimeUser?._type} />}
              <div className="md:grid space-y-5 md:space-y-0 grid-cols-2 gap-4 mb-4">
                <div className="flex items-center justify-between rounded bg-[#181b20] p-5">
                  <div>
                    <h1 className="text-2xl font-medium text-gray-300">
                      Earnings
                    </h1>
                    <p className="mt-2 text-lg text-gray-400">
                      {formatToCurrency(Number(realTimeUser?.earnings))}
                    </p>
                  </div>
                  <div>
                    <h2 className="text-gray-400 font-normal text-lg">
                      Management Fee
                    </h2>
                    <p className="mt-2 text-lg text-gray-400">
                      {formatToCurrency(Number(realTimeUser?.managementFee))}
                    </p>
                  </div>
                </div>
                <div className="rounded bg-[#181b20] px-5 py-5">
                  <div className="flex items-center justify-between">
                    <h1 className="text-2xl font-medium text-gray-300">
                      Deposits
                    </h1>
                    <button
                      onClick={() => {
                        if (!realTimeUser?.verified) {
                          alert(
                            "Please verify your account before depositing!"
                          );
                        } else {
                          navigate("/deposit");
                        }
                      }}
                      className="w-24 text-center bg-[#b2ffce] rounded-md px-4 py-1 outline-none"
                    >
                      Deposit
                    </button>
                  </div>
                  {/* {deposit && <Deposit />} */}
                  <div className="mt-3 flex items-center justify-between">
                    <div>
                      <h2 className="text-gray-400 font-normal text-lg">
                        Deposited
                      </h2>
                      <p className="mt-1 text-lg text-gray-400">
                        {realTimeUser?.deposits}
                      </p>
                    </div>
                    <div>
                      <h2 className="text-gray-400 font-normal text-lg">
                        Total
                      </h2>
                      <p className="mt-2 text-lg text-gray-400">
                        {formatToCurrency(Number(realTimeUser?.deposited))}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" mb-4 rounded bg-[#181b20] p-5">
                <h1 className="font-medium text-3xl mb-4 text-gray-300">
                  Deposits
                </h1>
                <div>
                  <DepositHistoryComponent />
                </div>
              </div>
              <div className=" mb-4 rounded bg-[#181b20] p-5">
                <h1 className="font-medium text-3xl mb-4 text-gray-300">
                  Investments
                </h1>
                <div>
                  <InvestmentTable />
                </div>
              </div>
              <DashboardFooter />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
