import { jwtDecode } from "jwt-decode";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedRoute = () => {
  const location = useLocation();
  const token = localStorage.getItem("access");

  function Redirect() {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < Date.now()) {
      localStorage.removeItem("access");
      return <Redirect />;
    } else {
      return <Outlet />;
    }
  } else {
    return <Redirect />;
  }
};

export default ProtectedRoute;
