/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  Area,
  Tooltip,
  CartesianGrid,
  LineChart,
  Line,
  YAxis,
} from "recharts";
import { format, parseISO, subDays } from "date-fns";

function normalizeValue(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

const randomValues = [
  86.92, 55.86, 72.52, 55.56, 68.08, 43.6, 67.19, 89.56, 56.24, 83.81, 87.37,
  43.49, 39.79, 81.25, 80.96, 60.29, 67.1, 49.93, 78.92, 42.07, 70.03, 57.77,
  51.23, 62.34, 74.19, 69.32, 19.0, 68.42, 89.9, 79.05, 48.01,
];

const Chart = ({ monthly, yearly, boxHeight }) => {
  const [chartData, setChartData] = useState([]);
  // const data = [];
  // for (let num = 30; num >= 0; num--) {
  //   console.log(num);
  //   data.push({
  //     date: subDays(new Date(), num).toISOString().substring(0, 10),
  //     performance: normalizeValue(randomValues[num], monthly, yearly),
  //   });
  // }

  // const generateSineData = (amplitude, frequency, numPoints) => {
  //   const data = [];
  //   const step = (2 * Math.PI) / numPoints; // Step for each point on x-axis (one full cycle)

  //   for (let i = 0; i < numPoints; i++) {
  //     const x = i * step;
  //     const y = Math.floor(amplitude * Math.sin(frequency * x));
  //     data.push({
  //       x: subDays(new Date(), i).toISOString().substring(0, 10),
  //       y: y,
  //     });
  //   }

  //   return data;
  // }

  const generateSineData = (
    amplitude,
    frequency,
    numPoints,
    minValue = 1.83
  ) => {
    const data = [];
    const step = (2 * Math.PI) / numPoints; // Step for each point on x-axis (one full cycle)

    const verticalShift = minValue + amplitude; // Shift the sine curve up so that values start at minValue

    for (let i = numPoints; i > 0; i--) {
      const x = i % 2 ? i * step : (i + 0.534) * step;
      const y = amplitude * Math.sin(frequency * x) + verticalShift;
      data.push({
        x: subDays(new Date(), i).toISOString().substring(0, 10),
        y: y,
      });
    }

    setChartData(data);
  };

  useEffect(() => {
    generateSineData(Number(yearly), Number(monthly), 30);
  }, []);
  // const data = generateSineData(Number(yearly), 0.3075, 30);
  // console.log({ data });
  return (
    <ResponsiveContainer width="100%" height={400}>
      {/* <LineChart width={600} height={300} data={data}>
        <Line type="monotone" dataKey="y" stroke="#b2ffce" />
        <XAxis dataKey="x" />
        <YAxis />
        <Tooltip />
      </LineChart> */}

      <AreaChart data={chartData}>
        <defs>
          <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#b2ffce" stopOpacity={0.4} />
            <stop offset="75%" stopColor="#b2ffce" stopOpacity={0.05} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="y"
          stroke="#808080"
          fill="url(#color)"
          dot={{
            stroke: "#b2ffce",
            strokeWidth: 4,
            // r: v,
            fill: "#b2ffce",
          }}
        />
        <XAxis
          dataKey="x"
          // axisLine={false}
          // tickLine={false}
          tickFormatter={(str) => {
            const date = parseISO(str);
            if (date.getDate()) {
              return format(date, "MMM, d");
            }
            return "";
          }}
        />
        <Tooltip content={<CustomTooltip />} />
        <CartesianGrid opacity={0.1} vertical={false} />
      </AreaChart>
    </ResponsiveContainer>
  );
};
//  <YAxis
// dataKey="performance"
// axisLine={false}
// tickLine={false}
// tickCount={8}
// tickFormatter={(number) => `${number.toFixed(2)}`}
// />

function CustomTooltip({ active, payload, label }) {
  if (active) {
    return (
      <div className="tooltip">
        <h4>{format(parseISO(label), "eeee, d, MMM, yyyy")}</h4>
        <p>Performance: {payload[0].value.toFixed(2)} %</p>
      </div>
    );
  }
  return null;
}

export default React.memo(Chart);
