import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages";
import About from "./pages/about";
import Contact from "./pages/contact";
import Dashboard from "./pages/dashboard";
import DepositHistory from "./pages/dashboard/deposit-history";
import Investment from "./pages/dashboard/investment";
import InvestmentHistory from "./pages/dashboard/investment-history";
import Kyc from "./pages/dashboard/kyc";
import Leaderboard from "./pages/dashboard/leaderboard";
import Pools from "./pages/dashboard/pools";
import WithdrawHistory from "./pages/dashboard/withdraw-history";
import Login from "./pages/login";
import Signup from "./pages/signup";
import Strategies from "./pages/strategies";
import Profile from "./pages/dashboard/settings";
import Pool from "./pages/dashboard/pool";
import FixedYield from "./pages/dashboard/fixed-yield";
import { Yield } from "./pages/dashboard/yield-item";
import TwoFactorAuth from "./pages/dashboard/TwoFA";
import TwoFALogin from "./pages/login-twofa";
import Deposit from "./pages/dashboard/deposit";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import VerifyEmail from "./pages/verify-email";
import PortfolioBuilder from "./pages/portfolio-builder";
import ProtectedRoute from "./components/ProtectedRoute";
import ComingSoon from "./pages/coming-soon";
// import Maintenance from "./components/Maintenance";
import NotFound from "./components/NotFound";
import BoostTier from "./pages/dashboard/boost-tier";
import UpgradeTier from "./pages/dashboard/upgrade-tier";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/about" element={<About />} />
        <Route path="/strategies" element={<Strategies />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login-twofa" element={<TwoFALogin />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="/portfolio-builder" element={<PortfolioBuilder />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/deposit" element={<Deposit />} />
          <Route path="/dashboard/strategies" element={<Pools />} />
          <Route path="/fixed-yields" element={<FixedYield />} />
          <Route path="/fixed-yield" element={<Yield />} />
          <Route path="/strategy/:id" element={<Pool />} />
          <Route path="/portfolio" element={<Investment />} />
          <Route path="/investment-history" element={<InvestmentHistory />} />
          <Route path="/deposit-history" element={<DepositHistory />} />
          <Route path="/twofa" element={<TwoFactorAuth />} />
          <Route path="/boost-tier" element={<BoostTier />} />
          <Route path="/upgrade-tier" element={<UpgradeTier />} />

          <Route path="/withdraw-history" element={<WithdrawHistory />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/kyc" element={<Kyc />} />
          <Route path="/profile" element={<Profile />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
