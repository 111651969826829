import React, { useState, useContext } from "react";
import Loader from "../../components/Loader";
import SidebarComponent from "../../components/navigation/Sidebar";
import Badge from "../../components/lib/Badge";
import TiersComparisonTable from "../../components/tables/TierComparisonTable";
import UpgradeOptions from "../../components/lib/UpgradeOptions";
import { impactOverview } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import { AuthContext } from "../../context/authContext";

export default function UpgradeTier() {
  const [upgradeOption, setUpgradeOption] = useState("");
  const { user } = useContext(AuthContext);
  const { realTimeUser } = useUser();
  const navigate = useNavigate();

  return (
    <div className="text-gray-300 tracking-wide">
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={user} />
      </div>
      <div className="p-4 lg:ml-64 bg-[#0a0c0f] flex-1">
        <div className="p-4 border border-gray-600 border-dashed flex-1 rounded-lg dark:border-gray-700 mt-20">
          <div className="flex flex-col gap-y-8">
            <div>
              <div>
                <div className="flex items-center gap-x-3">
                  <h1 className="text-2xl md:text-3xl font-medium">
                    Upgrade Your Tier
                  </h1>
                  {realTimeUser && (
                    <div className="">
                      <Badge user={realTimeUser} />
                    </div>
                  )}
                </div>
                <p>
                  Here's an overview of your current tier compared to the next
                  tier{" "}
                </p>
              </div>
              <div className="mt-3">
                <TiersComparisonTable userType={realTimeUser?._type} />
              </div>
            </div>
            <div>
              <h2 className="text-2xl font-medium mb-4">Upgrade options</h2>
              <UpgradeOptions
                selected={upgradeOption}
                handleChange={(value) => {
                  setUpgradeOption(value);
                }}
                fieldName="upgradeOption"
                userType={realTimeUser?._type}
                userRefers={realTimeUser?.refers.length}
                userDeposits={realTimeUser?.deposited}
              />
            </div>
            <div>
              <h2 className="text-2xl font-medium mb-4">
                Impact of the Boost Tier Program
              </h2>
              <ul className="grid md:grid-cols-2 gap-x-6 px-4">
                {impactOverview.map((impact, index) => (
                  <li key={index + 0.234} className="mb-3 list-disc">
                    <h4 className="font-medium text-lg">{impact.title}</h4>
                    <span>{impact.description}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="max-sm:flex-col-reverse flex items-center font-medium gap-4 sm:gap-6 justify-end">
              <button className="button max-sm:w-full">Help and Support</button>
              <button
                onClick={() => navigate("/boost-tier")}
                className="button max-sm:w-full"
              >
                Track my progress
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
