import React from "react";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import whitepaper from "../../assets/Algoo-Strategies-Whitepaper.pdf";
import useScript from "../../hooks/useScript";

const Footer = () => {
  useScript(
    "https://widgets.coingecko.com/coingecko-coin-price-marquee-widget.js"
  );
  return (
    <div className="bg-[#0a0c0f]">
      <coingecko-coin-price-marquee-widget
        coin-ids="bitcoin,ethereum,eos,ripple,litecoin,dogecoin,dash"
        currency="usd"
        background-color="#0a0c0f"
        locale="en"
        font-color="#ededed"
      ></coingecko-coin-price-marquee-widget>
      <div className="md:flex justify-between items-center mt-10">
        <div>
          <img className="w-[10%] md:w-[60%]" src={logo} alt="logo" />
          <p className="text-gray-400 text-sm my-10">
            Copyright © 2020-2024 <br /> Algoo Strategies. <br /> All rights
            reserved.
          </p>
        </div>
        <div className="md:grid space-y-5 md:space-y-0 grid-cols-4 gap-8">
          <div className="text-gray-400">
            <h1 className="text-gray-300 font-semibold text-xl mb-3">
              Resources
            </h1>
            <div className="space-y-2">
              <p className="hover:text-gray-300 hover:underline duration-500">
                <a
                  href="https://blog.algoostrategies.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Blog
                </a>
              </p>
              <p className="hover:text-gray-300 hover:underline duration-500">
                <a href="https://wiki.algoostrategies.org/algoo-strategies-wiki/">
                  Wiki
                </a>
              </p>
              <p className="hover:text-gray-300 hover:underline duration-500">
                <a href={whitepaper}>Whitepaper</a>
              </p>
              <p className="hover:text-gray-300 hover:underline duration-500">
                <a href="https://wiki.algoostrategies.org/algoo-strategies-wiki/algoo-strategies-platform/affiliate-program">
                  Affiliate Program
                </a>
              </p>
              <p className="hover:text-gray-300 hover:underline duration-500">
                <Link to="/terms">Terms of use</Link>
              </p>
              <p className="hover:text-gray-300 hover:underline duration-500">
                <Link to="/privacy">Privacy Policy</Link>
              </p>
            </div>
          </div>
          <div className="text-gray-400">
            <h1 className="text-gray-300 font-semibold text-xl mb-3">Help</h1>
            <div className="space-y-2">
              <p className="hover:text-gray-300 hover:underline duration-500">
                <Link to="/contact">Contact us</Link>
              </p>
              <p className="hover:text-gray-300 hover:underline duration-500">
                <a href="mailto:support@algoostrategies.org">Mail</a>
              </p>
              <p className="hover:text-gray-300 hover:underline duration-500">
                <a href="https://wiki.algoostrategies.org/algoo-strategies-wiki/faq/kyc">
                  KYC
                </a>
              </p>
            </div>
          </div>

          <div className="text-gray-400">
            <h1 className="text-gray-300 font-semibold text-xl mb-3">
              Products
            </h1>
            <div className="space-y-2">
              <p className="hover:text-gray-300 hover:underline duration-500">
                <Link to="/strategies">CeDefi Strategies</Link>
              </p>
              <p className="hover:text-gray-300 hover:underline duration-500">
                <Link to="/strategies#fixedYield">Fixed Yield Strategies</Link>
              </p>
            </div>
          </div>
          <div className="text-gray-400">
            <h1 className="text-gray-300 font-semibold text-xl mb-3">
              Socials
            </h1>
            <div className="space-y-2">
              {/* <p className="hover:text-gray-300 hover:underline duration-500">
                <a href="https://t.me/algoostrategies">Telegram Chat</a>
              </p>
              <p className="hover:text-gray-300 hover:underline duration-500">
                <a href="https://t.me/algoostrategies_announcement">
                  Telegram Channel
                </a>
              </p> */}
              <p className="hover:text-gray-300 hover:underline duration-500">
                <a href="https://www.linkedin.com/company/algoostrategies/">
                  Linkedin
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-14 md:mt-20 pb-10">
        <h2 className="text-gray-400">
          Disclaimer: Information contained on this website is for general
          information purpose only and may be inaccurate.
          <br />
          Cryptocurrency services are provided by Algoo Strategies.
          Cryptocurrencies are a high risk investment and cryptocurrency
          exchange rates have exhibited strong volatility. Exposure to potential
          loss could extend to your cryptocurrency investment.
          <br />
          Not all products and services are available in all geographic areas
          and are subject to applicable terms and conditions. Eligibility for
          particular products and services is subject to final determination by
          algoostrategies.org. Rates for algoostrategies.org products are
          subject to change.
          <br />
          Be aware of scams: algoostrategies.org provides its services only via
          https://algoostrategies.org/. Algoo Strategies is not affiliated with
          any other similar website domains and is not responsible for any acts
          taken by their owners.
        </h2>
      </div>
    </div>
  );
};

export default Footer;
