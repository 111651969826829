import React, { useState } from "react";

const Tooltip = ({ text, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  return (
    <div
      style={{ position: "relative", display: "inline-block" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="cursor-pointer"
    >
      {children}

      {isVisible && (
        <div
          className="animate-fadeIn bg-gray-800 rounded-lg p-2"
          style={styles.tooltip}
        >
          <p>{text}</p>
        </div>
      )}
    </div>
  );
};

const styles = {
  tooltip: {
    position: "absolute",
    bottom: "120%", // Position it above the element
    // left: "20px",
    // transform: "translateX(-50%)",
    // backgroundColor: "black",
    color: "white",
    // padding: "8px",
    // borderRadius: "4px",
    whiteSpace: "nowrap",
    fontSize: "12px",
    zIndex: 1000,
    visibility: "visible",
    transition: "opacity 0.3s ease-in-out",
  },
};

export default Tooltip;
