import React from "react";
import Loader from "../components/Loader";
import Navbar from "../components/Navbar";
import Footer from "../components/navigation/Footer";
import Livechat from "../components/LiveChat";

const Terms = () => {
  return (
    <div>
      <div className="bg-[#0a0c0f]">
        <div>
          <Livechat />
        </div>
        <div>
          <Loader />
        </div>
        <Navbar />
        <div className="max-lg:py-20 max-lg:px-5 lg:p-40 text-gray-300 space-y-10">
          <div>
            <h1 className="font-semibold text-3xl">Terms of use</h1>
            <div className="px-5 border-l-4 mt-2">
              <p className="font-bold">
                PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THE
                WEBSITE. ‍<br />
                ‍Disclaimer
                <br />
                <br />
                Nothing contained on the Website shall constitute or shall be
                deemed to constitute a financial, legal, tax or other advice of
                any kind, or a solicitation to, purchase, sell or invest in any
                Platform products (collectively, "Trading of cryptocurrencies")
                or to engage in any Yield-Automated-Portfolio strategy ("YAP").
                <br />
                <br />
                PLEASE READ THESE TERMS OF USE CAREFULLY AS THEY CONTAIN RISK
                NOTICE, ACKNOWLEDGEMENT AND ACCEPTANCE, NO WAIVER AND NO CLASS
                ACTION CLAUSE, WHICH AFFECT YOUR RIGHTS. IF YOU DO NOT AGREE TO
                THESE TERMS OF USE{" "}
                <a href="mailto:support@algoostrategies.org">
                  <b>SUPPORT@ALGOOSTRATEGIES.ORG</b>
                </a>
                <br />
                <br />
                Trading of cryptocurrencies, CeDeFi strategies and staking
                cryptocurrencies, including by accessing or using this website
                and the Services, as defined below, has potential rewards and
                risks involved. Transacting in cryptocurrencies, digital assets
                and DeFi assets, in general and including in Applicable Digital
                Assets, as well as Staking carries a high level of risk and is
                not suitable for all people. Anyone wishing to access and use
                the Services, as defined below, should seek their own
                independent professional legal, financial and tax advice.
              </p>
            </div>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl md:text-3xl">Preamble</h1>
            <p>
              AGREEMENT BETWEEN YOU AND ALGOO STRATEGIES GOVERNS ALL ASPECTS OF
              THE RELATIONSHIP BETWEEN YOU AND ALGOO STRATEGIES. This is a User
              Agreement between you (also referred to herein as “User,” or
              “Customer”) and ALGOO, Inc, a Geneva corporation, if you are a
              Swiss. citizen or Swiss resident; or ALGOO STRATEGIES Global,
              LLC., if you are not a Swiss. citizen or Swiss. resident
              (collectively, "ALGOO STRATEGIES," “we,” “us,” and “our”). This
              User Agreement ("Agreement" or “User Agreement”) governs your use
              of the services provided by ALGOO STRATEGIES described below and
              such other services that may be offered by ALGOO STRATEGIES from
              time to time ("Services"). By signing up to use a ALGOO
              STRATEGIES’ account or service through ALGOOSTRATEGIES.com, or any
              other ALGOO STRATEGIES’ website (collectively the "ALGOO
              STRATEGIES Site"), you agree that you have read, understand, and
              accept all of the terms and conditions contained in this Agreement
              including our Privacy Policy. If you do not agree to any part of
              the Agreement, you may not use the Services (as defined below).
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">
              ALGOO STRATEGIES SERVICES
            </h1>
            <p>
              Through your ALGOO STRATEGIES’ account (“ALGOO STRATEGIES
              Account”) and the ALGOO STRATEGIES Site you are able to stake
              cryptocurrency tokens and have access to trading algorithms, in
              addition to certain other services that may be provided by ALGOO
              STRATEGIES from time to time, including, among other things,
              Rewards Program (as defined below), third-party account management
              tools, general news and information, market data including price
              and analytics, and educational information (collectively, the
              “Services”). Some of the Services may be restricted in your
              jurisdiction depending on local laws and regulations. ALGOO
              STRATEGIES may, in our absolute and sole discretion, with or
              without prior notice and at any time, modify or discontinue,
              temporarily or permanently, any portion of our Services.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">ELIGIBILITY</h1>
            <p>
              You must be 18 years old to use the ALGOO STRATEGIES Site or the
              Services and represent that you are of such age in doing so. If
              you are using the Services on behalf of a legal entity, you
              further represent and warrant that the legal entity is duly
              organized and validly existing under the applicable laws of the
              jurisdiction of its organization, and you are duly authorized by
              such legal entity to act on its behalf. Furthermore, you represent
              and warrant that you meet the following requirements: (i) you have
              not previously been suspended or removed from using our Services;
              (ii) you have full power and authority to enter into this
              Agreement and in doing so will not violate any other agreement to
              which it is a party; (iii) you are not located in, under the
              control of, or a national of any restricted location or any
              country to which Switzerland or the EU have embargoed goods or
              services; (iv) neither you nor any authorized person on your ALGOO
              STRATEGIES Account has been identified as a “Specially Designated
              National” by FDFA; (v) no one employed by you or who is an
              investor or principal has been placed on the Federal Department of
              Finance’s Denied Persons List; and (vi) you will not use our
              Services if any applicable laws in the country of its
              incorporation or operation prohibits you from doing so in
              accordance with this Agreement. ALGOO STRATEGIES may not make all
              the Services available in all markets and jurisdictions and may
              restrict or prohibit use of all or a portion of the Services from
              certain countries, states, territories, or jurisdictions.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">ACCOUNT REGISTRATION</h1>
            <p>
              In order to use any of the Services, you must first register
              through the ALGOO STRATEGIES Site and provide certain information.
              ALGOO STRATEGIES may, in its absolute and sole discretion, refuse
              to allow you to establish an ALGOO STRATEGIES Account.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">AVAILABLE JURISDICTIONS</h1>
            <p>
              The Customer further understands that if the Customer attempts to
              access the Account from a jurisdiction subject to certain Swiss.
              sanctions or if the Customer is ordinarily resident in such a
              jurisdiction, or if ALGOO STRATEGIES reasonably believes that the
              Customer is attempting such access or has become a resident in
              such a jurisdiction, ALGOO STRATEGIES may restrict the Account,
              and any pending orders may be canceled. If this happens, the
              Customer understands that the Customer should contact
              support@algoostrategies.org, and that the Customer may be asked to
              provide supplemental information as part of this process. The
              Customer further understands that the Customer must close all
              Accounts before establishing residency in any jurisdiction subject
              to Swiss. sanctions.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">KYC</h1>
            <p>
              In order to use certain features of the Services and to help
              governments fight the funding of terrorism and money laundering
              activities, you may be required to provide ALGOO STRATEGIES with
              certain personal information, including, but not limited to, your
              name, address, telephone number, e -mail address, date of birth,
              government identification number, front and back picture of a
              valid drivers license or a picture of your international passport.
              In submitting this or any other personal information as may be
              required, you verify that the information is accurate and
              authentic, and you agree to update ALGOO STRATEGIES if any
              information changes. You hereby authorize ALGOO STRATEGIES to,
              directly or through third-parties, make any inquiries we consider
              necessary to verify your identity and/or protect against fraud,
              including to query identity information contained in public
              reports (e.g., your email address, phone number, name, address,
              past addresses, or date of birth), to query information associated
              with your linked passport (e.g., name or country), and to take
              action we reasonably deem necessary based on the results of such
              inquiries and reports. You further authorize any and all third
              parties to which such inquiries or requests may be directed to
              fully respond to such inquiries or requests. If applicable, you
              further authorize your wireless operator to disclose your mobile
              number, name, address, email, network status, customer type,
              customer role, billing type, mobile device identifiers (IMSI and
              IMEI) and other subscriber details, if available, to us and
              service providers for the duration of the business relationship,
              for identity verification and fraud avoidance. See our Privacy
              Policy for how we treat your data.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">ACCOUNT FUNDING</h1>
            <p>
              ALGOO STRATEGIES only allows cryptocurrency deposits at this time
              to conduct cryptocurrency trades outlined in the ‘TRADING’ section
              below. When Customer initiates a cryptocurrency deposit (a
              “Cryptocurrency Deposit”) into the Customer’s Account, Customer is
              solely responsible for executing the Cryptocurrency Deposit
              properly and accurately. ALGOO STRATEGIES is not responsible for
              any Cryptocurrency until such time as such Cryptocurrency is
              successfully deposited into the relevant wallet address provided
              by ALGOO STRATEGIES to Customer as evidenced by such deposit
              appearing on the relevant block explorer for such deposit. ALGOO
              STRATEGIES is not responsible for any delay s, losses or fees in
              connection with a Cryptocurrency Deposit and is not obligated to
              assist or support Customer in any fashion with respect to an
              unsuccessful Cryptocurrency Deposit or with respect to any issues
              that Customer may experience at a point in time prior to the
              successful completion of a Cryptocurrency Deposit; provided,
              however, that ALGOO STRATEGIES may, in its sole discretion,
              provide reasonable assistance to Customer in the event that a
              Customer requests assistance in connection with an attempted,
              failed, erroneous, or otherwise incomplete Cryptocurrency Deposit,
              including but not limited to, those deposits sent by Customer to a
              Cryptocurrency wallet designated by ALGOO STRATEGIES for a
              different type of Cryptocurrency than the Cryptocurrency being
              sent by Customer, whether or not such deposit is evidenced on the
              blockchain. ALGOO STRATEGIES does not guarantee that any such
              assistance will result in the successful completion or remediation
              of a Cryptocurrency Deposit or the recovery of any Cryptocurrency.
              Customer understands that a Cryptocurrency Deposit cannot be
              reversed once properly initiated.
              <br />
              <br />
              Customer authorizes and instructs ALGOO STRATEGIES to hold
              Customer’s Cryptocurrency (when deposited by Customer into the
              Account pursuant to the Cryptocurrency Deposit mechanics outlined
              above) on its behalf. Customer understands that ALGOO STRATEGIES
              may hold Customer’s Cryptocurrency together with the
              Cryptocurrency of other ALGOO STRATEGIES customers in omnibus
              accounts or wallets. In addition, Customer understands and
              authorizes ALGOO STRATEGIES to delegate some or all custody
              functions to one or more Affiliates or third parties (which may
              include, but not be limited to exchanges and custodians) at ALGOO
              STRATEGIES’s discretion (each a “Custodian”). Some or all custody
              functions provided by a Custodian may be performed, supported, or
              conducted in foreign jurisdictions, or conducted by Custodians
              domiciled, registered, or subject to the laws and regulations of
              foreign jurisdictions. ALGOO STRATEGIES will exercise reasonable
              skill and care in the selection, appointment, and periodic review
              of any such Custodian. ALGOO STRATEGIES will maintain true,
              complete and accurate records relating to Custo mer
              Cryptocurrency. Customer and ALGOO STRATEGIES understand that the
              legal treatment of Cryptocurrency is unsettled and disparate
              across different jurisdictions.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">
              PRIVACY AND INFORMATION STORAGE
            </h1>
            <p>
              You consent to us accessing, processing and retaining any personal
              information you provide to us for the purpose of us providing
              ALGOO STRATEGIES Services to you. This consent is not related to,
              and does not affect, any rights or obligations we or you have in
              accordance with data protection laws, privacy laws and
              regulations. You can withdraw your consent at any time by closing
              your account with us. However, we may retain and continue to
              process your personal information if we reasonably believe it is
              necessary in order to comply with laws or regulations. In
              providing us with this or any other information that may be
              required, you confirm that the information is accurate and
              authentic. You agree to keep us updated if any of the information
              you provide changes. You authorize us to make inquiries, whether
              directly or through third parties, that we consider necessary to
              verify your identity or protect you and/or us against fraud or
              other financial crime, and to take action we reasonably deem
              necessary based on the results of such inquiries. When we carry
              out these inquiries, you acknowledge and agree that your personal
              information may be disclosed to credit reference and fraud
              prevention or financial crime agencies and that these agencies may
              respond to our inquiries in full. This is an identity check only
              and should have no adverse effect on your credit rating. We
              reserve the right at all times to monitor, review, retain and/or
              disclose any information as necessary to satisfy any applicable
              law, regulation, sanctions programs, legal process or governmental
              request. See our Privacy Policy and Cookie Policy for more
              information on how we process your personal data and the rights
              you have in respect of this.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">TRADING</h1>
            <p>
              CRYPTOCURRENCY RISK DISCLOSURES
              <br /> Cryptocurrencies are highly speculative in nature, involve
              a high degree of risk and can rapidly and significantly decrease
              in value. It is reasonably possible for the value of
              Cryptocurrencies to decrease to zero or near zero. Cryptocurrency
              held on the ALGOO STRATEGIES Platform is not protected by FDIC
              insurance or any other government-backed or third-party insurance.
              The future development of Cryptocurrencies and their underlying
              software protocols is uncertain. The slowing or stopping of the
              development or acceptance of Cryptocurrencies may negatively
              affect their value. Cryptocurrencies are not regulated or are
              lightly regulated in most countries, including Switzerland.
              However, one or more countries may take regulatory actions that
              could severely restrict the right to acquire, own, hold, sell or
              use Cryptocurrencies, which would adversely impact their value.
              ALGOO STRATEGIES may be forced to suspend or discontinue the
              ability to purchase or sell Cryptocurrencies without notice.
              Technical and market factors outside of ALGOO STRATEGIES’s control
              may adversely affect the value of Cryptocurrencies. These factors
              include, without limitation, (i) technical defects, limitations or
              changes to a given Cryptocurrency; (ii) Forks (as discussed in the
              User Agreement); and (iii) the emergence of competing
              Cryptocurrencies.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">STORAGE</h1>
            <p>
              As part of your ALGOO STRATEGIES Account, ALGOO STRATEGIES will
              provide qualifying users access to store, track, transfer, and
              manage your balances of Digital Assets. As used throughout, "
              Digital Asset" means only those particular digital assets listed
              as available to trade or store in your ALGOO STRATEGIES Account
              (also referred to as “Supported Digital Asset”). Services and
              supported assets may vary by jurisdiction, with no jurisdictions
              supported for fiat currency including the US Dollar, Euro, and
              GBP. We securely store Digital Asset private keys, which are used
              to process transactions, in a combination of online and offline
              storage. As a result of our security protocols, it may be
              necessary for us to retrieve private keys or related information
              from offline storage in order to facilitate Digital Asset
              Transfers in accordance with your instructions, and you
              acknowledge that this may delay the initiation or crediting of
              such Digital Asset Transfers.
              <br />
              <br />
              Your ALGOO STRATEGIES Account is intended solely for proper use of
              Supported Digital Assets as designated on the ALGOO STRATEGIES
              Site. Under no circumstances should you attempt to use your ALGOO
              STRATEGIES Account to store, send, request, or receive any assets
              other than Supported Digital Assets. ALGOO STRATEGIES assumes no
              responsibility in connection with any attempt to use your ALGOO
              STRATEGIES Account with digital assets that we do not support. You
              acknowledge and agree that ALGOO STRATEGIES is not liable for any
              unsupported digital asset that is sent to a wallet associated with
              your ALGOO STRATEGIES Account. ALGOO STRATEGIES may in its sole
              discretion terminate support for any particular Digital Asset. If
              you do not sell or send such Digital Asset off platform before
              ALGOO STRATEGIES terminates its support for such Digital Asset,
              then ALGOO STRATEGIES may, in its sole discretion, remove such
              digital asset from your ALGOO STRATEGIES Account and credit your
              ALGOO STRATEGIES Account the equivalent market value of a
              supported Digital Asset minus transaction costs.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">TITLE</h1>
            <p>
              With respect to any cryptocurrency you sell, transfer or deliver
              to ALGOO STRATEGIES, you represent and warrant that you are the
              lawful owner or possessor of such cryptocurrency with good and
              marketable title thereto, and you have the absolute right to sell,
              assign, convey, transfer and deliver such cryptocurrency. You
              further represent you are the lawful owner or possessor of any
              external wallet you may use to settle transactions hereunder, and
              that you have good title thereto and that such wallet is owned and
              operated for your benefit, and no person or entity, has better
              rights, title, control or interest in any such wallet. Title to
              Digital Assets shall at all times remain with you and sh all not
              transfer to ALGOO STRATEGIES. As the owner of Digital Assets in
              your ALGOO STRATEGIES Account, you shall bear all risk of loss of
              such Digital Assets. Except as required by law, or except as
              provided herein, ALGOO STRATEGIES will not sell, transfer, loan,
              hypothecate, or otherwise alienate Digital Assets in your ALGOO
              STRATEGIES Account unless instructed by you.
              <br />
              <br />
              You control the Digital Assets held in your ALGOO STRATEGIES
              Account. At any time, subject to outages, downtime, and other
              applicable policies, you may withdraw your Digital Assets by
              sending it to a different blockchain address. As long as you
              continue to store your Digital Assets with ALGOO STRATEGIES, ALGOO
              STRATEGIES shall retain control over electronic private keys
              associated with blockchain addresses operated by ALGOO STRATEGIES,
              including the blockchain addresses that hold your Digital Assets.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">BOTS</h1>
            <p>
              The Services may include the availability of trading algorithms or
              bots to help you automate trading strategies. A strategy can be as
              simple as buying a small amount of an asset over a period of time
              to more complex automations such as grid trading and portfolio
              rebalancing. which allow you to configure the parameters of the
              chosen bot (for example, DCA Bot, GRID Bot, etc.) that will
              execute cryptocurrency trade orders automatically on the ALGOO
              STRATEGIES platform or third-party cryptocurrency exchanges within
              those established parameters and without the need for additional
              human interaction or intervention. The trading algorithms are
              provided by third-party users of the Services and not
              independently tested, approved, recommended, or guaranteed by
              ALGOO STRATEGIES. You should take independent financial advice
              from a professional in connection with, or independently research
              and verify, any information that is provided by us of the
              third-parties that you wish to rely upon, whether for the purpose
              of making an investment decision or otherwise. Any content, data,
              information, or publications made available through the third
              parties in connection with use of the trading algorithms are
              furnished by us on an as-is basis for your convenience and
              information. Any opinions, advice, statements, services, offers,
              or other information made available by third parties are those of
              the respective author(s) or publisher(s), and not of ALGOO
              STRATEGIES. Such information should not be interpreted as approval
              by ALGOO STRATEGIES of the content or information you may obtain
              from them. ALGOO STRATEGIES has no control over the content or
              information of these resources and ALGOO STRATEGIES disclaims any
              warranty or representation, either express or implied, that the
              information in such publications is accurate or complete. Past
              performances by the algorithms available to you are not an
              accurate predictor of future performance, and there are no
              guarantees that the algorithms will work under all market
              conditions. You represent and warrant that you understand the
              risks of using trading algorithms provided as part of the Services
              and assume those risks in their entirety.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">FEES</h1>
            <p>
              An applicable trading fee, as indicated on the ALGOO STRATEGIES
              Site, applies to all transactions and will vary depending on the
              pricing methodology or algorithm you choose to execute the
              transaction. You further understand that we may also receive
              remuneration from any counterparty on a portion of such charges,
              commissions or fees and that we will retain such rebates as part
              of our overall compensation. Trading fees may be variable and
              subject to discounts depending on how the fees are paid. For
              example, discounted fees may be available if certain digital
              assets, including those generated and made available by ALGOO
              STRATEGIES, are used rather than BTC or stablecoins. All fee rates
              will be published and made available on the ALGOO STRATEGIES site
              and any order form, clearly visible prior to any trade execution.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">TRANSACTION LIMITS</h1>
            <p>
              The use of all Services is subject to a limit on the amount of
              volume you may transact or transfer in a given period, stated in
              U.S. Dollar terms. Your transaction limits may vary depending on
              your payment method, identity verification steps you have
              completed, and other factors. ALGOO STRATEGIES reserves the right
              to change applicable limits as we deem necessary in our absolute
              and sole discretion. If you wish to raise your limits beyond the
              posted amounts, you may submit a request via the ALGOO STRATEGIES
              Site or by contacting support@algoostrategies.org. We may require
              you to submit additional information about yourself or your
              business, provide records, or arrange for meetings with ALGOO
              STRATEGIES representatives.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">
              SITE ACCURACY NOT GUARANTEED
            </h1>
            <p>
              We make no representation or warranty that the ALGOO STRATEGIES
              Site, or the server that makes it available, is free of viruses or
              errors, that its content is accurate, that it will be
              uninterrupted, or that defects will be corrected. We will not be
              responsible or liable to you for any loss of any kind, from action
              taken, or taken in reliance on content, material, or information
              contained on the ALGOO STRATEGIES Site. Links to third-party
              materials may be provided as a convenience but are not controlled
              by us. You acknowledge and agree that we are not responsible for
              any aspect of the information, content, or services contained in
              any third-party materials or on any third-party sites accessible
              or linked to the ALGOO STRATEGIES Site. You acknowledge that the
              prices displayed on the ALGOO STRATEGIES Site are proprietary and
              not taken directly from any one source, and therefore may not
              match the prices that you see elsewhere (including prices quoted
              on certain exchanges or marketplaces). You further acknowledge
              that the triggering of your transaction is linked to the prices we
              quote on the ALGOO STRATEGIES Site. We attempt to display prices
              on an ongoing basis and to have the currently applicable prices
              displayed on the ALGOO STRATEGIES Site as quickly as possible,
              however, the market for cryptocurrency is extremely volatile and
              prices often change faster than communication speed, which could
              result in “stale” prices being displayed on the ALGOO STRATEGIES
              Site. In addition, there will be times when circumstances beyond
              ALGOO STRATEGIES’s control may prevent the ALGOO STRATEGIES Site
              from displaying prices or otherwise impact the prices being
              displayed.
            </p>
          </div>

          <div className="">
            <h1 className="font-semibold text-2xl">COMPLETED TRANSACTIONS</h1>
            <p>
              ALGOO STRATEGIES will make reasonable efforts to accept trades or
              transactions, but we are under no obligation whatsoever to accept
              any specific transaction or order, and may discontinue services in
              our sole discretion in accordance with this Agreement. You cannot
              cancel, reverse, or change any transaction marked as complete or
              pending. You are responsible for maintaining an adequate balance
              and/or sufficient credit limits in order to avoid overdraft,
              insufficient funds, or similar fees charged by your payment
              provider. ALGOO STRATEGIES reserves the right to refuse to
              process, or to cancel or reverse, any purchases or sales, or any
              applicable transactions or payments, in its sole discretion, even
              after funds have been credited to or debited from your account(s),
              under the following circumstances: (a) if the transaction, in our
              absolute and sole discretion, constitutes an abusive use of the
              platform; (b) involves an error with respect to price, quantity,
              or other parameters; (c) is required by law or if ALGOO STRATEGIES
              suspects the transaction involves or potentially involved (or has
              a high risk of involvement in) restricted businesses as described
              in Section 8 of this Agreement, money laundering, terrorist
              financing, fraud, or any other type of financial crime; (d) in
              response to a subpoena, court order, or other government order. In
              such instances, ALGOO STRATEGIES may reverse, cancel, or refuse to
              process the payment or transaction and we are under no obligation
              to allow you to reinstate a purchase or sale order at the same
              price or on the same terms as the reversed or canceled transaction
              and you hereby release us from all liability in relation to any
              action taken by us under this section.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">TAXES</h1>
            <p>
              It is your sole responsibility to determine whether, and to what
              extent, any taxes apply to any transactions you conduct through
              the Services, and to withhold, collect, report and remit the
              correct amounts of taxes to the appropriate tax authorities. ALGOO
              STRATEGIES does not deduct any amount for taxes when you enter
              into a transaction, and you are solely responsible for all tax
              returns and payments required to be filed with or made to any
              federal, state, or local tax authority in any nation with respect
              to any such transaction. All amounts set out, or expressed to be
              payable hereunder by you to ALGOO STRATEGIES, shall be deemed to
              be exclusive of any value added tax (“VAT”). If VAT is chargeable,
              you shall pay to ALGOO STRATEGIES an amount equal to such VAT.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">REWARDS</h1>
            <p>
              Customer may elect to participate in the ALGOO STRATEGIES Rewards
              Program (the “Rewards Program”), which provides rewards for what
              is commonly known as “staking”, which allows Customer to earn
              additional Cryptocurrency of the same kind of Cryptocurrency held
              in Customer's Account. The terms and conditions governing the
              Rewards Program are as follows:
              <ul className="list">
                <li>
                  Each Customer participating in the Rewards Program agrees to
                  allow ALGOO STRATEGIES to rehypothecate the Customer’s
                  Cryptocurrency held in such Customer Account to the extent
                  that Customer wishes to stake their Cryptocurrency. Such
                  consent to rehypothecate expressly includes allowing ALGOO
                  STRATEGIES to (1) stake Cryptocurrency held in an omnibus
                  fashion through various blockchain protocols; and (2) lend
                  such Cryptocurrency to various institutional third parties
                  (each, a “Borrower”) determined at ALGOO STRATEGIES’s sole
                  discretion (each, a “Loan”). ALGOO STRATEGIES enters into
                  these Loans as principal and independently negotiates with
                  each Borrower the terms of a Loan, but these Loans are
                  generally unsecured, for a fixed term of less than one year or
                  can be repaid on a demand basis, and provide a fee payable in
                  Cryptocurrency based on the percentage and denominated in the
                  Cryptocurrency lent. ALGOO STRATEGIES selects which
                  Cryptocurrencies are available for such staking and lending at
                  its sole discretion, subject to limitations on amounts
                  allowable and additional terms and conditions.
                </li>
                <li>
                  ALGOO STRATEGIES will typically publish anticipated reward
                  rates once per month on or before the first business day of
                  each month. Reward rates may be tiered, with specified rates
                  in effect at any time only applied to specified portions of
                  amounts of Cryptocurrency held in the Account. Rewards will be
                  payable in arrears and added to the Account on or before the
                  fifth business day of each calendar month for the prior
                  calendar month. ALGOO STRATEGIES uses the daily balance method
                  to calculate the Rewards on the Account. This method applies a
                  daily periodic rate to the specified principal in the Account
                  each day. The daily periodic rate is calculated by dividing
                  the applicable interest rate by three hundred sixty-five (365)
                  days, even in leap years. ALGOO STRATEGIES will determine the
                  Reward rates and tiers for each month in ALGOO STRATEGIES’s
                  sole discretion, and Customer acknowledges that such Rewards
                  may not be equivalent to benchmark interest rates observed in
                  the market for bank deposit accounts.
                </li>
                <li>
                  Rewards will be credited to the Account within five (5)
                  business days following the end of each calendar month. The
                  Account must be open on such date in order to receive this
                  payment of Rewards. All Rewards will be paid in
                  Cryptocurrency. Once Rewards have been credited to the
                  Account, Customer may earn Rewards on such Cryptocurrency in
                  future months. Rewards will be paid in kind (i.e., in the type
                  of Cryptocurrency that is held in the Account).
                </li>
                <li>
                  Customer may request a Withdrawal at any time, consistent with
                  the mechanics outlined in Section 6 below. However, for
                  Customers in the Rewards Program, Withdrawals may be delayed,
                  or in some instances subject to partial completion. Customer
                  understands and acknowledges that Customer may only be able to
                  earn Rewards to the extent that Customer satisfies certain
                  minimum Cryptocurrency balance requirements (“Minimum
                  Balance”). ALGOO STRATEGIES will publish Minimum Balance
                  details on the Platform. Minimum Balance details are subject
                  to change at any time. If a Withdrawal results in a particular
                  Cryptocurrency balance in Customer’s Account falling below a
                  Minimum Balance, then Customer will not earn Rewards with
                  respect to such Cryptocurrency.
                </li>
                <li>
                  REWARDS PROGRAM RISKS. Participating in the Rewards Program
                  may put Customer's Cryptocurrency at risk as loans made by
                  ALGOO STRATEGIES may not be secured. Customer may opt-out of
                  the Rewards Program at any time by doing so on the ALGOO
                  STRATEGIES site.
                </li>
              </ul>
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">WITHDRAWALS</h1>
            <p>
              Customer may arrange to withdraw and transfer Cryptocurrency in
              the Account to an external wallet (such process, a “Withdrawal”).
              ALGOO STRATEGIES will effectuate a Withdrawal based upon
              Customer’s written instructions; provided that Customer
              understands and agrees that ALGOO STRATEGIES may, in its sole
              discretion, delay, modify or prohibit, in whole or in part, any
              requested Withdrawal, including in instances where: (a) ALGOO
              STRATEGIES believes such action is prudent in order to satisfy
              ALGOO STRATEGIES’s anti-money laundering obligations, (b) ALGOO
              STRATEGIES suspects that the Customer, the wallet address, or the
              Withdrawal itself are connected to, associated with, or being used
              in furtherance of potential fraud, (c) Customer is in violation of
              the Customer Agreement, (d) the Withdrawal is being attempted
              within thirty (30) days of a deposit of Cryptocurrency into the
              Account, or (e) outstanding fees are associated with the Account
              or the Account would, after the Withdrawal, have an insufficient
              balance to cover actual or anticipated fees. Customer understands
              that, once initiated on the network associated with the
              Cryptocurrency subject to the Withdrawal, a Withdrawal will
              typically be processed at the speed of such network, but that in
              certain situations, a Withdrawal may be delayed in connection with
              any latency, congestion, disruption, or other delay of such
              network. Customer understands that ALGOO STRATEGIES cannot reverse
              a Withdrawal that has been broadcast to a Cryptocurrency network.
              Customer also understands that ALGOO STRATEGIES reserves the right
              to cancel any pending Withdrawal as required by law or in response
              to a subpoena, court order, or other binding government order.
              <br />
              Customer understands that they are exclusively responsible for
              ensuring that a Withdrawal is being made to the correct or
              intended wallet address. Withdrawals cannot be reversed once
              broadcast to the relevant Cryptocurrency network. ALGOO STRATEGIES
              will not be liable for any loss that results from inaccurate,
              incomplete, or misleading details that Customer may provide in
              connection with a Withdrawal. ALGOO STRATEGIES will not bear any
              liability for any failure, error or delay in processing a
              Withdrawal. ALGOO STRATEGIES may, in its sole discretion, provide
              reasonable assistance to Customer in the event that a customer
              requests reasonable assistance in connection with an attempted,
              failed, or otherwise erroneous Withdrawal. ALGOO STRATEGIES does
              not guarantee that any such assistance will result in the
              successful completion or remediation of a Withdrawal or the
              recovery of any Cryptocurrency. ALGOO STRATEGIES may charge fees
              in connection with any such assistance. Customer understands that
              a Withdrawal cannot be reversed once properly initiated.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">ASSUMPTION OF RISK</h1>
            <p>
              You acknowledge that there are risks associated with using the
              Services and the ALGOO STRATEGIES Site, including but not limited
              to risk that the underlying protocols of supported networks
              undergo material changes to their operating rules, risk of failure
              of hardware, software and Internet connections, the risk of
              malicious software introduction, and the risk that third-parties
              may obtain unauthorized access to information stored within your
              ALGOO STRATEGIES Account. As such, ALGOO STRATEGIES will not be
              responsible or liable to you for any such related losses and takes
              no responsibility for use of the Services that involves user error
              such as forgotten passwords, incorrectly construed transactions,
              or mistyped wallet addresses; server failure or data loss; corrupt
              files; unauthorized access, or; any unauthorized third-party
              activities, including without limitation the use or introduction
              of computer viruses, or other malware, phishing, spoofing or other
              means of attack against you or the ALGOO STRATEGIES Site or
              Services. You further accept and acknowledge that ALGOO STRATEGIES
              will not be responsible for any communication failures,
              disruptions, errors, distortions or delays you may experience when
              using the ALGOO STRATEGIES Site or the Services, regardless of the
              cause. Cryptocurrency is not covered by any insurance, including
              insurance offered by the Swiss Financial Market Supervisory
              Authority (FINMA). You agree and understand that the underlying
              protocols of supported cryptocurrency networks are subject to
              changes in operating rules or other valid operational protocol (a
              so-called “Fork”) that may result in more than one version (each,
              a “Forked Network”) and ALGOO STRATEGIES holding an identical
              amount of cryptocurrency associated with each Forked Network. You
              further agree and understand that Forks may materially affect the
              value, function, and/or name of the cryptocurrency you may have
              with ALGOO STRATEGIES. In the event of a Fork, you agree and
              understand that ALGOO STRATEGIES may temporarily suspend our
              operations (with or without advance notice to you) while we
              choose, in our sole and absolute discretion, which Forked Networks
              to support. You agree and understand that in our best estimation
              we are unlikely to support most Forked Networks and that the
              cryptocurrency of most Forked Networks will likely not be made
              available to you. ALGOO STRATEGIES makes no guarantees or
              recommendations of any kind, nor does it purport to offer any
              investment advice of any kind. The risk of complete loss in
              trading or holding any type of cryptocurrency can be substantial
              and is likely. You are solely responsible for evaluating the
              merits and risks associated with the use of the ALGOO STRATEGIES
              Site and the Services and should take into consideration your
              particular financial situation in determining whether using the
              Services or trading cryptocurrencies is suitable for you. You
              hereby represent that your ALGOO STRATEGIES Account is completely
              discretionary in nature, and is not being used primarily for
              personal, family, or household purposes.
              <br />
              <br />
              No Investment Advice or Brokerage: For the avoidance of doubt,
              ALGOO STRATEGIES does not provide investment, tax, or legal
              advice, and you are solely responsible for determining whether any
              investment, investment strategy or related transaction is
              appropriate for you based on your personal investment objectives,
              financial circumstances and risk tolerance. ALGOO STRATEGIES may
              provide educational information about Supported Digital Assets, as
              well as digital assets not supported by ALGOO STRATEGIES, in order
              to assist users in learning more about such digital asset.
              Information may include, but is not limited to, blog posts,
              articles, links to third-party content, news feeds, tutorials, and
              videos. The information provided on the ALGOO STRATEGIES Site or
              any such third-party sites does not constitute investment advice,
              financial advice, trading advice, or any other sort of advice, and
              you should not treat any of the website's content as such. ALGOO
              STRATEGIES does not recommend that any Digital Asset should be
              bought, earned, sold, or held by you. ALGOO STRATEGIES will not be
              held responsible for the decisions you make to buy, sell, or hold
              Digital Assets based on the information provided by ALGOO
              STRATEGIES. ALGOO STRATEGIES does not broker trades on your behalf
              unless otherwise specified on a specific product. All ALGOO
              STRATEGIES trades are executed automatically, based on the
              parameters of your order instructions and in accordance with
              posted trade execution procedures.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">
              PERMITTED USE & PROHIBITED ACTIVITIES
            </h1>
            <p>
              You agree and acknowledge that that you will not use the ALGOO
              STRATEGIES Site or the Services in connection with any unlawful or
              otherwise undesirable activity (as determined by ALGOO STRATEGIES
              in its absolute and sole discretion), including, but not limited
              to dealing in regulated products or services,
              pseudo-pharmaceuticals, adult content and services,
              unfair/predatory practices, counterfeit goods, gambling, suspected
              money laundering, or drug activity. In addition, you represent
              that your ALGOO STRATEGIES Account will not be used to circumvent,
              disable, or otherwise interfere with security-related features of
              the ALGOO STRATEGIES Site, including features that prevent or
              restrict the use or copying of any Content or enforce limitations
              on the use of the Site and/or the Content contained therein or
              engage activity which would violate, or assist in violation of,
              any law, statute, ordinance, regulation, or sanctions programs
              administered in the countries where ALGOO STRATEGIES conducts
              business, including but not limited to the Swiss Financial Market
              Supervisory Authority ("FINMA"), or which would involve proceeds
              of any unlawful activity; publishing, distributing or
              disseminating any unlawful material or information. Other
              prohibited activities include using the ALGOO STRATEGIES Site to
              advertise or offer to sell goods and services; making any
              unauthorized use of the ALGOO STRATEGIES Site, including
              collecting usernames and/or email addresses of users by electronic
              or other means for the purpose of sending unsolicited email, or
              creating user accounts by automated means or under false
              pretenses; interfere with, disrupt, or create an undue burden on
              the ALGOO STRATEGIES Site or the networks or services connected to
              the Site; sell or otherwise transfer your profile; use any
              information obtained from the ALGOO STRATEGIES Site in order to
              harass, abuse, or harm another person; decipher, decompile,
              disassemble, or reverse engineer any of the software comprising or
              in any way making up a part of the ALGOO STRATEGIES Site; attempt
              to bypass any measures of the ALGOO STRATEGIES Site designed to
              prevent or restrict access to the ALGOO STRATEGIES Site, or any
              portion of the ALGOO STRATEGIES Site; harass, annoy, intimidate,
              or threaten any of our employees or agents engaged in providing
              any portion of the Services to you; copy or adapt the ALGOO
              STRATEGIES Site’s software, including but not limited to Flash,
              PHP, HTML, JavaScript, or other code; upload or transmit (or
              attempt to upload or transmit) viruses, Trojan horses, or other
              material, including excessive use of capital letters and spamming
              (continuous posting of repetitive text), that interferes with any
              party’s uninterrupted use and enjoyment of the Site or modifies,
              impairs, disrupts, alters, or interferes with the use, features,
              functions, operation, or maintenance of the Site; use ALGOO
              STRATEGIES Account information of another party to access or use
              the ALGOO STRATEGIES Site, except in the case of specific
              merchants and/or applications which are specifically authorized by
              a user to access such user's ALGOO STRATEGIES Account and
              information; or transfer your account access or rights to your
              account to a third party, unless by operation of law or with the
              express permission of ALGOO STRATEGIES.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">LIMITED LICENSE</h1>
            <p>
              We grant you a limited, nonexclusive, nontransferable license,
              subject to the terms of this Agreement, to access and use the
              ALGOO STRATEGIES Site and limited and related websites, content,
              materials, and information (collectively, the “Content”) solely
              for approved purposes as permitted by ALGOO STRATEGIES from time
              to time. Any other use of the ALGOO STRATEGIES Site is expressly
              prohibited and all other right, title, and interest in the ALGOO
              STRATEGIES Site is exclusively the property of ALGOO STRATEGIES
              and its licensors. You agree you will not copy, transmit,
              distribute, sell, license, reverse engineer, modify, publish, or
              participate in the transfer or sale of, create derivative works
              from, or in any other way exploit any of the ALGOO STRATEGIES Site
              or its related content, in whole or in part. “ALGOO STRATEGIES”
              and “ALGOO STRATEGIES.org” and all logos related to the Services
              or displayed on the ALGOO STRATEGIES Site are either trademarks or
              registered marks of ALGOO STRATEGIES or its licensors. You may not
              copy, imitate or use them without our prior written consent.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">INFORMATION SECURITY</h1>
            <p>
              You acknowledge that the username or identification numbers used
              to access your ALGOO STRATEGIES Account are unique and that you
              alone have assigned a unique password to such username or
              identification number. The password you have created to access
              your ALGOO STRATEGIES Account is strictly confidential and should
              only be known to you. You must not share or disclose the password
              to anyone, and assure the confidentiality of your password at all
              times. You are responsible for protecting your ALGOO STRATEGIES
              Account and maintaining adequate security and control of all IDs,
              passwords, hints, personal identification numbers (PINs), or any
              other codes that are used to access the Services and will not
              share ALGOO STRATEGIES Account credentials with third-parties. Any
              loss or compromise of the foregoing information or any personal
              information may result in unauthorized access to your ALGOO
              STRATEGIES Account, and any associated external accounts, by third
              parties and the loss or theft of assets. You are responsible for
              providing accurate and complete ALGOO STRATEGIES Account
              information and for keeping email addresses and telephone numbers
              up to date in your ALGOO STRATEGIES Account profile to receive any
              notices or alerts that ALGOO STRATEGIES may send Client. ALGOO
              STRATEGIES assumes no responsibility for any loss that Client may
              sustain due to compromise of ALGOO STRATEGIES Account credentials
              due to no fault of ALGOO STRATEGIES or failure to follow or act on
              any notices or alerts that ALGOO STRATEGIES may send to you. In
              the event you believe your ALGOO STRATEGIES Account information
              has been compromised, contact ALGOO STRATEGIES customer support
              immediately at support@algoostrategies.org.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">USER CONSENT</h1>
            <p>
              You hereby grant ALGOO STRATEGIES a royalty-free, fully paid-up,
              sublicensable, transferable, perpetual, irrevocable,
              non-exclusive, worldwide license to use, copy, modify, create
              derivative works of, display, perform, publish and distribute, in
              any form, medium or manner, any text, information, data,
              materials, images, or other content you provide to ALGOO
              STRATEGIES using the services that is not your personal data (the
              “Client Content”). You represent and warrant that (i) you own the
              Client Content or have the right to grant the rights and licenses
              in this Agreement, and (ii) the Client Content and use by ALGOO
              STRATEGIES of the Client Content as licensed herein does not and
              will not violate, misappropriate or infringe on the rights of any
              third-party. ALGOO STRATEGIES may remove any Client Content from
              the ALGOO STRATEGIES Site for any reason at ALGOO STRATEGIES’s
              discretion.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">
              CUSTOMER REPRESENTATIONS & WARRANTIES
            </h1>
            <p>
              Customer represents, warrants, and/or acknowledges, as applicable,
              as of the date of this Customer Agreement, and on each date that
              the Customer utilizes the Services:
              <ul className="list">
                <li>
                  Customer understands and acknowledges that their Account is
                  self-directed, and that Customer is solely responsible for any
                  and all orders placed in the Account. Customer understands
                  that Customer is fully responsible for safeguarding Customer’s
                  login credentials and that Customer is responsible for any
                  trade placed through, originating from, or associated with the
                  Account whether placed by Customer or another third-party as a
                  result of Customer’s failure to safeguard the login
                  credentials. Customer represents that all orders entered by
                  Customer through the Account are unsolicited and based upon
                  Customer’s decisions.
                </li>
                <li>
                  Customer understands that ALGOO STRA TEGIES, together with its
                  affiliates, does not provide recommendations or any investment
                  advice. Customer represents that Customer has not received any
                  investment advice from ALGOO STRATEGIES, or its affiliates,
                  and does not expect to receive any investment advice from
                  ALGOO STRATEGIES or any of its affiliates. Customer
                  understands and agrees that under no circumstances will
                  Customer’s use of the Platform or Account be deemed to create
                  a relationship that includes the provision of or tendering of
                  investment advice.
                </li>
                <li>
                  To the extent that research materials or similar information
                  is made available through the Platform, the Customer
                  understands that these materials are intended for information
                  and educational purposes only and they do not constitute a
                  recommendation to enter into any transactions or to engage in
                  any investment strategies.
                </li>
                <li>
                  Customer represents and warrants to ALGOO STRATEGIES that
                  Customer is not: (a) located in, under the control of, or a
                  national or resident of any country to which Switzerland has
                  embargoed goods or services, (b) identified as a “Specially
                  Designated National,” (c) placed on the Federal Department of
                  Finance's Denied Persons List, and (d) a person who is subject
                  to any law, regulation, or list of any government authority
                  (including, without limitation, the Swiss department of
                  Foreign Asset Control list) that would prohibit or limit ALGOO
                  STRATEGIES’s ability to conduct business with Customer.
                  Customer further represents and warrants that Customer will
                  not use the Platform if the laws of Customer’s country or
                  jurisdiction prohibit Customer from doing so in accordance
                  with this Agreement.
                </li>
                <li>
                  Customer: (i) certifies that the information contained in this
                  Agreement, the Account application, and any other document
                  that Customer furnishes to ALGOO STRATEGIES in connection with
                  the Account is complete, true, and correct; (ii) authorizes
                  ALGOO STRATEGIES to contact any individual or firm noted on
                  documents provided to ALGOO STRATEGIES; (iii) authorizes
                  anyone so contacted to furnish such information to ALGOO
                  STRATEGIES as ALGOO STRATEGIES may request; and (iv) agrees
                  that this Customer Agreement, the Account application, and any
                  other document Customer furnishes in connection with the
                  Account is ALGOO STRATEGIES’s property. Customer shall
                  promptly advise ALGOO STRATEGIES of any changes to the
                  information in such agreements and documents in writing within
                  ten (10) calendar days. ALGOO STRATEGIES may retain this
                  Customer Agreement, the Account application, and all other
                  such documents and their respective records at ALGOO
                  STRATEGIES’s sole discretion. Customer understands that ALGOO
                  STRATEGIES may take steps to verify the accuracy of the
                  information Customer provides to ALGOO STRATEGIES in the ALGOO
                  STRATEGIES Account application or otherwise, including by
                  directly or indirectly making any inquiries ALGOO STRATEGIES
                  considers necessary to verify Customer identity or protect
                  against fraud and that ALGOO STRATEGIES may restrict Customer
                  access to the Account or take other action ALGOO STRATEGIES
                  reasonab ly deems necessary pending such verification.
                </li>
                <li>
                  The Customer understands that all investments involve risk,
                  that losses may exceed the principal invested, and that the
                  past performance of a Cryptocurrency, industry, sector,
                  market, or financial product does not guarantee future results
                  or returns. Customer further understands that there are risks
                  associated with utilizing an internet-based trading system
                  including, without limitation, the failure of hardware,
                  software and Internet connections as well as the risk of
                  malicious software introductions.
                </li>
                <li>
                  Customer understands that the Service is not provided to, and
                  may not be used by, any person in any jurisdiction where the
                  provision or use thereof would be contrary to applicable laws
                  and regulations. ALGOO STRATEGIES is not available in all
                  jurisdictions. Customer agrees to refrain from using the
                  Service if Customer begins to reside in a jurisdiction where
                  the Service would violate any of the laws and regulations of
                  such jurisdiction. Customer will not provide incorrect
                  information about Customer’s address, residency or domicile
                  and will immediately inform ALGOO STRATEGIES if there is any
                  change to previously provided information.
                </li>
                <li>
                  Customer understands that neither the Customer Agreement or
                  any other document or communication received from ALGOO
                  STRATEGIES shall be construed as providing any legal,
                  accounting, estate, actuary, or tax advice. Customer agrees to
                  review publicly available information regarding the Customer’s
                  positions in the Account, Account statements and Trade
                  Confirmations. Customer must rely upon its own
                  representatives, including its own legal counsel and
                  accountant, as to legal, tax and related matters concerning
                  any of Customer’s activities with respect to the Account,
                  including any assets or transactions in the Account and for
                  preparation of any legal, accounting or tax documents.
                </li>
              </ul>
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">LIMITATION OF LIABILITY</h1>
            <p>
              IN NO EVENT SHALL ALGOO STRATEGIES, ITS AFFILIATES AND SERVICE
              PROVIDERS, OR ANY OF THEIR RESPECTIVE OFFICERS, DIRECTORS, AGENTS,
              JOINT VENTURERS, EMPLOYEES OR REPRESENTATIVES, BE LIABLE FOR LOST
              PROFITS OR ANY SPECIAL, INCIDENTAL, INTANGIBLE, OR CONSEQUENTIAL
              DAMAGES, WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT
              LIABILITY, OR OTHERWISE, ARISING OUT OF OR IN CONNECTION WITH ANY
              USE OF THE ALGOO STRATEGIES SITE OR THE SERVICES, OR THIS
              AGREEMENT. THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE”
              BASIS WITHOUT ANY REPRESENTATION OR WARRANTY, WHETHER EXPRESS,
              IMPLIED OR STATUTORY. ALGOO STRATEGIES SPECIFICALLY DISCLAIMS ANY
              IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE AND/OR NON-INFRINGEMENT. ALGOO STRATEGIES DOES
              NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT ACCESS TO THE
              ALGOO STRATEGIES SITE, ANY PART OF THE ALGOO STRATEGIES SERVICES,
              OR ANY OF THE MATERIALS CONTAINED THEREIN, WILL BE CONTINUOUS,
              UNINTERRUPTED, TIMELY, OR ERROR-FREE. ALGOO STRATEGIES makes no
              representations about the accuracy or completeness of any data,
              including historical price data, available on the ALGOO STRATEGIES
              Site. ALGOO STRATEGIES will make commercially reasonable efforts
              to ensure that requests for electronic debits and credits are
              processed in a timely manner but ALGOO STRATEGIES makes no
              representations or warranties regarding the amount of time needed
              to complete processing which is dependent upon many factors
              outside of our control.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">TERMINATION</h1>
            <p>
              (A) Termination by Customer. Customer may request to close or
              terminate the Account at any time by notifying
              support@algoostrategies.org and requesting in writing that their
              Account be closed. Closing the Account will not affect any rights
              and obligations incurred prior to the date of Account closure.
              Upon the termination of the Account, Customer authorizes ALGOO
              STRATEGIES to immediately settle all outstanding transactions in
              the Account and liquidate outstanding positions in the Account, as
              necessary. Customer shall be required to provide transfer
              instructions with respect Cryptocurrencies remaining in the
              Account. Customer understands and agrees that Customer is
              responsible for any fees, costs, expenses, charges, or obligations
              (including, but not limited to, attorney and court fees or
              transfer costs of funds or Cryptocurrency) associated with closing
              the Account. In the event that the costs of closing the Account
              exceed the value in the Account, Customer will be responsible for
              reimbursing ALGOO STRATEGIES. ALGOO STRATEGIES may delay or refuse
              to terminate an Account, in instances it deems appropriate or
              necessary, in its sole discretion, including but not limited to,
              where: (a) ALGOO STRATEGIES believes such action is prudent in
              order to satisfy ALGOO STRATEGIES’s anti-money laundering
              obligations, (b) ALGOO STRATEGIES suspects that the Customer is
              connected to, engaged in, or acting in furtherance of fraud, or
              potential fraud, (c) Customer is in violation of the Customer
              Agreement, (d) termination is being attempted within sixty (60)
              days of a deposit of USD or Cryptocurrency into the Account, or
              (e) the Account has outstanding actual or anticipated fees or
              other charges against it (the “ALGOO STRATEGIES Hold Purposes”).
              (B) Termination by ALGOO STRATEGIES. Customer agrees that, without
              notice, ALGOO STRATEGIES may terminate this Customer Agreement,
              suspend, restrict, limit or shutdown all or part of the Services,
              the Account, as well as Customer’s access to the Platform, with or
              without cause at any time and effective immediately, whether for
              maintenance or otherwise. ALGOO STRATEGIES shall not be liable to
              Customer or any third party for the termination or suspension of
              the Services or Platform, or any claims related to such
              termination or suspension.
              <br />
              (C) Effect of Termination. In the event that your ALGOO STRATEGIES
              Account is suspended or terminated, we will immediately cancel all
              open orders associated with your ALGOO STRATEGIES Account, block
              all withdrawals and bar the placing of further orders until
              resolution or Account cancellation. Upon termination or
              cancellation of the Account, Customer must provide ALGOO
              STRATEGIES with transfer instructions, together with all other
              information that ALGOO STRATEGIES may reasonably request, in order
              to transfer any remaining Cryptocurrency out of the Account. All
              such transfers must be completed within ninety (90) days following
              Account deactivation or cancellation. ALGOO STRATEGIES may delay
              or refuse to effectuate such a transfer or termination, in
              instances it deems appropriate or necessary, in its sole
              discretion. If Cryptocurrency is not transferred out of the
              Account within ninety (90) days of the termination or cancellation
              of the Account, Customer hereby agrees that ALGOO STRATEGIES is
              permitted to sell any remaining Cryptocurrency on the open market
              at the prevailing market price and return the proceeds (less any
              damages, fee, costs, or other obligations to which ALGOO
              STRATEGIES is entitled) to any valid account linked to the
              Account.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">INDEMNIFICATION</h1>
            <p>
              You agree to indemnify and hold harmless ALGOO STRATEGIES, its
              affiliates and service providers, and each of its or their
              respective officers, directors, agents, joint venturers, employees
              and representatives, from any loss, damage, cost, action, claim or
              demand (including attorneys’ fees and any fines, fees or penalties
              imposed by any regulatory authority) arising out of or related to
              your breach of this Agreement; your use of, or conduct in
              connection with, the Services; or your violation of any law, rule
              or regulation, or the rights of any third-party. To the fullest
              extent permitted by applicable law, you release ALGOO STRATEGIES
              and the other ALGOO STRATEGIES parties from responsibility,
              liability, claims, demands, or damages (actual and consequential)
              of every kind and nature, known and unknown (including, but not
              limited to, claims of negligence), arising out of or related to
              disputes between users and the acts or omissions of third-parties.
              <br />
              <br />
              THE CUSTOMER UNDERSTANDS AND AGREES THAT ALGOO STRATEGIES, ITS
              AFFILIATES, AND THEIR RESPECTIVE PARTNERS, MANAGING DIRECTORS,
              DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, AND THIRD PARTY PROVIDERS
              WILL NOT BE LIABLE TO THE CUSTOMER OR TO THIRD PARTIES UNDER ANY
              CIRCUMSTANCES, OR HAVE ANY RESPONSIBILITY WHATSOEVER, FOR ANY
              SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES
              (INCLUDING, WITHOUT LIMITATION, LOST PROFITS, TRADING LOSSES, AND
              DAMAGES) THAT THE CUSTOMER MAY INCUR IN CONNECTION WITH THE
              CUSTOMER’S USE OF THE SERVICES (INCLUDING BUT NOT LIMITED TO THIRD
              PARTY SERVICES) OR THE PLATFORM PROVIDED BY ALGOO STRATEGIES UNDER
              THE CUSTOMER AGREEMENT. ALGOO STRATEGIES, ITS AFFILIATES AND ITS
              AND THEIR RESPECTIVE PARTNERS, DIRECTORS, OFFICERS, EMPLOYEES, AND
              AGENTS SHALL NOT BE LIABLE BY REASON OF DELAYS OR INTERRUPTIONS OF
              INFORMATION, THE PLATFORM OR SERVICES (INCLUDING BUT NOT LIMITED
              TO THIRD PARTY SERVICES) OR TRANSMISSIONS, OR FAILURES OF
              PERFORMANCE OF ALGOO STRATEGIES’, ITS AFFILIATES, OR THIRD PARTY
              SYSTEMS, REGARDLESS OF CAUSE, INCLUDING, WITHOUT LIMITATION, THOSE
              CAUSED BY GOVERNMENTAL OR REGULATORY ACTION, THE ACTION OF ANY
              EXCHANGE OR OTHER SELF REGULATORY ORGANIZATION, OR THOSE CAUSED BY
              SOFTWARE OR HARDWARE MALFUNCTIONS.
              <br />
              <br />
              Except as otherwise provided by law, ALGOO STRATEGIES, its
              Affiliates and their respective partners, directors, officers,
              employees or agents (collectively, “Indemnified Parties”) shall
              not be liable for any expenses, losses, costs, damages,
              liabilities, demands, debts, obligations, penalties, charges,
              claims, causes of action, penalties, fines and taxes of any kind
              or nature (including, without limitation, legal expenses and
              attorneys’ fees) (whether known or unknown, absolute or
              contingent, liquidated or unliquidated, direct or indirect, due or
              to become due, accrued or not accrued, asserted or unasserted,
              related or not related to a third party claim, or otherwise)
              (collectively, “Losses”) by or with respect to any matters
              pertaining to the Customer Agreement, the Services, Information,
              or third party Services, except to the extent that such Losses are
              actual Losses and are determined by a court of competent
              jurisdiction or an arbitration panel in a final non-appealable
              judgment or order to have resulted solely from the ALGOO
              STRATEGIES’s gross negligence or intentional misconduct. In
              addition, the Customer agrees that the Indemnified Parties shall
              have no liability for, and the Customer agrees to indemnify,
              defend and hold harmless the Indemnified Parties from all Losses
              that result from: (i) any noncompliance by the Customer with any
              of the terms and conditions this Agreement; (ii) any third party
              actions related to the Customer’s receipt and use of any
              Information, third party Services, other third party content, or
              other such information obtained through the Platform, whether
              authorized or unauthorized under the Customer Agreement; (iii) any
              third party actions related to the Customer’s use of the Platform,
              Information, or third party Services; (iv) the Customer or the
              Customer’s agent’s misrepresentation or alleged misrepresentation,
              or act or omission; (v) Indemnified Parties following the Customer
              or the Customer’s agent’s directions or instructions, or failing
              to follow the Customer or the Customer’s agent’s unlawful or
              unreasonable directions or instructions; (vi) any activities or
              services of the Indemnified Parties in connection with an Account
              (including, without limitation, any technology services,
              reporting, trading, or research services or (viii) the failure by
              any person not controlled by the Indemnified Parties and their
              Affiliates to perform any obligations to the Customer. Further, if
              the Customer authorizes or allows third parties to gain access to
              the Platform, Information or the Account, the Customer will
              indemnify, defend and hold harmless ALGOO STRATEGIES, its
              affiliates and its and their respective directors, officers,
              employees and agents against any Losses arising out of claims or
              suits by such third parties based upon or relating to such access
              and use. ALGOO STRATEGIES does not warrant against loss of use or
              any direct, indirect or consequential damages or Losses to the
              Customer caused by the Customer’s assent, expressed or implied, to
              a third party accessing an Account or information.
              <br />
              <br />
              CUSTOMER AGREES TO INDEMNIFY ALGOO STRATEGIES FOR ACTUAL,
              REASONABLE LEGAL COSTS AND EXPENSES DIRECTLY RELATED TO THE
              ACCOUNT THAT ARE A RESULT OF ANY REGULATORY INQUIRY, LEGAL ACTION,
              LITIGATION, DISPUTE, OR INVESTIGATION THAT ARISES OR RELATES TO
              CUSTOMER OR CUSTOMER’S USE OF THE ACCOUNT OR THE SERVICES.
              CUSTOMER UNDERSTANDS THAT, AS A RESULT, ALGOO STRATEGIES WILL BE
              ENTITLED TO CHARGE THE ACCOUNT FOR SUCH COSTS WITHOUT NOTICE,
              INCLUDING LEGAL AND ENFORCEMENT RELATED COSTS THAT ALGOO
              STRATEGIES INCURS. ANY WITHHOLDING WILL LAST FOR A PERIOD OF TIME
              THAT IS REASONABLY NECESSARY TO RESOLVE ANY REGULATORY OR LEGAL
              ISSUE AT HAND, AND ALGOO STRATEGIES MAY PLACE ANY AMOUNTS GARNERED
              FROM CUSTOMER IN A SEPARATE ACCOUNT, AND WILL PAY TO CUSTOMER THE
              REMAINING BALANCE AFTER ANY NOTED ISSUE HAS BEEN RESOLVED.
              FURTHERMORE, CUSTOMER AGREES THAT WHERE SUCH ACTIONS RELATE TO A
              SPECIFIC ASSET IN THE ACCOUNT, THAT ASSET MAY NOT BE TRANSFERRED
              OUT OF THE ACCOUNT UNTIL THE MATTER IS RESOLVED.
              <br />
              ARBITRATION
              <br />
              If you have a dispute with ALGOO STRATEGIES, we will attempt to
              resolve any such disputes through our support team. If we cannot
              resolve the dispute through our support team, you and ALGOO
              STRATEGIES agree that any dispute arising under this Agreement
              shall be finally settled in binding arbitration, on an individual
              basis, and you and ALGOO STRATEGIES hereby expressly waive trial
              by jury and right to participate in any action involving
              collective, consolidated, or representative proceedings, including
              a class action lawsuit or class-wide arbitration.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">GOVERNING LAW</h1>
            <p>
              If you are a ALGOO STRATEGIES, Inc. customer, you agree that the
              laws of the State of Zurich,Switzerland without regard to
              principles of conflict of laws, will govern this Agreement and any
              claim or dispute that has arisen or may arise between you and
              ALGOO STRATEGIES, Inc. If you are a customer of ALGOO STRATEGIES
              Global Ltd., you agree that this Agreement shall be governed by
              and construed and enforced in accordance with the Laws of
              Switzerland, without regard to principles of conflict of laws,
              will govern this Agreement and any claim or dispute that has
              arisen or may arise between you and ALGOO STRATEGIES Global LLC.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">FORCE MAJEURE</h1>
            <p>
              ALGOO STRATEGIES shall not be liable for delays, failure in
              performance or interruption of service which result directly or
              indirectly from any cause or condition beyond our reasonable
              control, including but not limited to, any delay or failure due to
              any act of God, act of civil or military authorities, act of
              terrorists, civil disturbance, war, strike or other labor dispute,
              fire, interruption in telecommunications or Internet services or
              network provider services, failure of equipment and/or software,
              other catastrophe or any other occurrence which is beyond our
              reasona
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">NO WAIVER</h1>
            <p>
              This Agreement shall not be construed to waive rights that cannot
              be waived under applicable governing laws where you are located.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">AMENDMENTS</h1>
            <p>
              We may amend or modify this Agreement, in our absolute and sole
              discretion, by posting on the ALGOO STRATEGIES Site or emailing to
              you the revised Agreement, and the revised Agreement shall be
              effective at such time. If you do not agree with any such
              modification, your sole and exclusive remedy is to terminate your
              use of the Services and close your account. You agree that we
              shall not be liable to you or any third-party for any modification
              or termination of the Services, or suspension or termination of
              your access to the Services.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">ENTIRE AGREEMENT</h1>
            <p>
              This Agreement and the Privacy Policy incorporated by reference
              herein comprise the entire understanding and agreement between you
              and ALGOO STRATEGIES as to the subject matter hereof, and
              supersedes any and all prior discussions, agreements and
              understandings of any kind (including without limitation any prior
              versions of this Agreement), and every nature between and among
              you and ALGOO STRATEGIES. Section headings in this Agreement are
              for convenience only, and shall not govern the meaning or
              interpretation of any provision of this Agreement. The Parties
              understand and agree that all terms and conditions in this
              Agreement that require continued performance, compliance, or
              effect beyond the termination date of the Agreement shall survive
              such termination date and shall be enforceable in the event of a
              failure to perform or comply.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">SEVERABILITY</h1>
            <p>
              If any provision of this Agreement is determined to be invalid or
              unenforceable under or by any regulation, law, court of competent
              jurisdiction, or any government agency, such provision will be
              changed and interpreted to accomplish the objectives of the
              provision to the greatest extent possible under any applicable law
              and the validity or enforceability of any other provision of this
              Agreement shall not be affected.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">NO ASSIGNMENTS</h1>
            <p>
              You may not assign any rights and/or licenses granted under this
              Agreement. We reserve the right to assign our rights without
              restriction, including without limitation to any ALGOO STRATEGIES
              affiliates or subsidiaries, or to any successor in interest of any
              business associated with the Services. Any attempted transfer or
              assignment in violation hereof shall be null and void.
            </p>
          </div>
        </div>
        <div className="px-5 sm:px-10 md:px-20 lg:px-32">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Terms;
