import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo-full.png";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className="bg-[#0a0c0f] border-b-[0.5px] border-b-gray-500">
      <div className="lg:px-32 lg:flex justify-between items-center py-7 max-lg:relative">
        <div className="max-lg:flex items-center justify-between px-4">
          <img className="w-[30%] lg:w-[60%]" src={logo} alt="logo" />
          <button
            className="relative lg:hidden w-10 h-10 text-gray-500 rounded-sm focus:outline-none"
            onClick={() => setOpen(!open)}
          >
            <span className="sr-only">Open main menu</span>
            <div className="absolute block w-5 transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
              <span
                aria-hidden="true"
                className={`block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${
                  open ? "rotate-45" : "-translate-y-1.5"
                }`}
              ></span>
              <span
                aria-hidden="true"
                className={`block absolute  h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${
                  open && "opacity-0"
                }`}
              ></span>
              <span
                aria-hidden="true"
                className={`block absolute  h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${
                  open ? "-rotate-45" : "translate-y-1.5"
                }`}
              ></span>
            </div>
          </button>
        </div>
        <div
          className={`text-gray-400 max-lg:mt-10 font-medium text-lg lg:text-base lg:flex max-lg:absolute lg:space-x-5 lg:items-center bg-[#0a0c0f] max-lg:w-full max-lg:min-h-[400px] max-lg:z-50 lg:mx-0 mx-auto pt-14 lg:pt-0 transform transition duration-500 ease-in-out  ${
            open ? "max-lg:translate-x-0" : "max-lg:translate-x-[-100%]"
          }`}
        >
          <Link className="hover:text-gray-300 duration-500" to="/">
            <h2 className="mb-3 lg:mb-0 text-center">Home</h2>
          </Link>
          <Link className="hover:text-gray-300 duration-500" to="/about">
            <h2 className="mb-3 lg:mb-0 text-center">About us</h2>
          </Link>
          <Link className="hover:text-gray-300 duration-500" to="/strategies">
            <h2 className="mb-3 lg:mb-0 text-center">Strategies</h2>
          </Link>
          <a
            className="hover:text-gray-300 duration-500"
            href="https://blog.algoostrategies.org"
          >
            <h2 className="mb-3 lg:mb-0 text-center">Blog</h2>
          </a>
          <a
            className="hover:text-gray-300 duration-500"
            href="https://wiki.algoostrategies.org/algoo-strategies-wiki/"
          >
            <h2 className="mb-3 lg:mb-0 text-center">Wiki</h2>
          </a>
          <Link className="hover:text-gray-300 duration-500" to="/contact">
            <h2 className="mb-3 lg:mb-0 text-center">Contact</h2>
          </Link>
          <Link to="/dashboard">
            <div className="text-gray-800 duration-500 bg-[#b2ffce] max-lg:w-52 max-lg:mx-auto py-2 px-5 rounded-xl">
              <h2 className="text-center">Dashboard</h2>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
