import React from "react";
import Footer from "../components/navigation/Footer";
import Form from "../components/Form";
import Navbar from "../components/Navbar";
import Loader from "../components/Loader";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Livechat from "../components/LiveChat";

const Contact = () => {
  return (
    <div>
      <div className="bg-[#0a0c0f]">
        <div>
          <Livechat />
        </div>
        <div>
          <Loader />
        </div>
        <Navbar />
        <div className="px-5 sm:px-10 md:px-20 lg:px-32 pt-16">
          <div className="md:flex justify-between items-center min-h-screen w-full">
            <div className="md:max-w-xl">
              <div class="relative snap-y snap-mandatory overflow-y-auto scroll-smooth">
                <div class="group w-full snap-start">
                  <div>
                    <div class="overflow-hidden">
                      <div class="group-hover/wrap:translate-y-full group-only:animate-reveal group-only:animation-delay-[4000ms]">
                        <p className="text-gray-400 mb-2 tracking-widest font-medium">
                          GOT A QUESTION?
                        </p>
                      </div>
                    </div>
                    <div class="overflow-hidden">
                      <div class="group-hover/wrap:translate-y-full group-only:animate-reveal group-only:animation-delay-[4300ms]">
                        <h1 className="text-gray-300 text-4xl md:text-6xl mb-2 font-medium text-transparent bg-clip-text bg-gradient-to-r from-purple-200 via-[#b2ffce] to-purple-200">
                          Send us a message! <br />
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-gray-400 mb-10 md:mb-0">
                We are here to help and answer any questions you might have. We
                look forward to hearing from you.
              </p>
            </div>
            <div>
              <div className="md:min-w-[600px] fadeIn">
                <Form />
              </div>
            </div>
          </div>
          <div>
            <AnimationOnScroll animateIn="animate__fadeIn">
              <Footer />
            </AnimationOnScroll>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
